<template>
  <div>
    <div class="attach-sub">
      <label class="btn-outline attach-doc" :for="inputId" style="box-sizing: border-box;vertical-align: middle;"
        ><span class="txt ico-attach">
        {{buttonTitle}}
      </span></label
      >
      <a v-bind:href="url" v-if="url !== '' && url !== null" target="_blank" >{{ title }}</a>
      <a v-bind:href="url" v-if="url === '' || url === null" >{{ title }}</a>
    </div>
    <input
      type="file"
      :id="inputId"
      style="display:none;"
      @change="onFileChange"
    />
  </div>
</template>

<script>
export default {
  name: "FileUpload",
  props: ["title", "buttonTitle", "inputId","originUrl"],
  data() {
    return {
        url : ''
    };
  },
  beforeMount() {},
  mounted() {
    if (this.originUrl != null ) {
      this.url = this.originUrl;
    }

  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      console.log(file);
      this.upload();
    },
    upload() {
      var frm = new FormData();
      var fileInput = document.getElementById(this.inputId);
      frm.append("file", fileInput.files[0]);
      this.$axios
        .post("upload", frm, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          console.log(response.data);
          if (response.data.success) {
            var param = {
              id: this.inputId,
              url: response.data.data
            };
            this.url = response.data.data;
            this.$emit('uploaded', param);
            this.$alert.show(response.data.message);
          } else {
            this.$alert.show(response.data.message);
          }
        })
        .catch(ex => {
          this.$alert.show("에러 : " + ex);
        });
    }
  }
};
</script>

<style scoped></style>
