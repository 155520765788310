<template>
  <input
    type="text"
    ref="inputComma"
    :value="commaValue"
    @change="updateChange"
    @input="updateInput"
  />
</template>
<script>
export default {
  name: "CommaInput",
  props: ["value"],
  data() {
    return {
      modelValue: "",
      commaValue: ""
    };
  },
  mounted() {
    setTimeout(() => {
      console.log("this.modelValue ; " + this.value);
      if (this.value !== undefined) {
        this.modelValue = this.value;
        this.commaValue = this.modelValue.toLocaleString("en-US");
      }
    }, 500);

  },
  beforeMount() {

  },
  methods: {
    updateChange(e) {
      this.modelValue = parseInt(e.target.value.replace(/,/g, "")) || 0;
      this.commaValue = this.modelValue.toLocaleString("en-US");

      this.$emit("change", this.modelValue);
    },
    updateInput(e) {
      this.modelValue = parseInt(e.target.value.replace(/,/g, "")) || 0;
      this.commaValue = this.modelValue.toLocaleString("en-US");
      this.$emit("input", this.modelValue);
    }
  }
};
</script>
<style scoped></style>
