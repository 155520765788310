<template>
  <div class="sub-container flex-sb">
    <div class="content col" style="width:14%;">
      <section class="section">
        <h2 class="page-title">컨텐츠 관리</h2>
        <div class="contents-menu-box">
          <h3 class="contents-menu-title">
            <a href="#/notice">공지사항 / 이벤트</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/banner">배너</a></h3>
          <h3 class="contents-menu-title"><a href="#/fund">모금자리</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/health-column">건강칼럼</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/faq">FAQ</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/support">지원과목 / 지원항목</a>
          </h3>
        </div>
      </section>
    </div>
    <div class="content col" style="width:86%;">
      <section class="section">
        <h3 class="section-title">{{ contentsTitle }} 상세정보</h3>
        <table class="tbl-view app-detail-info input">
          <colgroup>
            <col class="head" />
            <col class="data" />
          </colgroup>
          <tbody>
          <tr>
            <th >상태</th>
            <td >
              <div class="t-radio-group" style="width:120px;">
                <div class="radio">
                  <input type="radio" id="radio01" name="radio" value="fundraising" v-model="data.status">
                  <label for="radio01">모금중</label>
                </div>

                <div class="radio">
                  <input type="radio" id="radio02" name="radio"  value="end" v-model="data.status">
                  <label for="radio02">종료</label>
                </div>

              </div>
            </td>
          </tr>
          <tr>
            <th>타이틀</th>
            <td>
              <input type="text" class="inp-txt-common full" v-model="data.title" >
            </td>
          </tr>
          <tr>
            <th>본문</th>
            <td>
              <input type="text" class="inp-txt-common full" v-model="data.body" >
            </td>
          </tr>
          <tr>
            <th>목표금액</th>
            <td>

              <comma-input   class="inp-txt-common full" v-model="data.targetAmount"  />
            </td>
          </tr>
          <tr>
            <th>상시</th>
            <td>
              <div class="custom-checkbox"   >
                <input
                        type="checkbox"
                        id="checkbox-always"
                        :value="true"
                        v-model="data.always"
                />
                <label for="checkbox-always" >상시</label>
              </div>

            </td>
          </tr>
          <tr v-if="data.always != true">
            <th>시작일</th>
            <td>
              <div class="ts-date-picker-s" >
                <input type="date" class="ts-date-picker__inp-s inp-txt-common alone"  v-model="data.start">
              </div>


            </td>
          </tr>
          <tr v-if="data.always != true">
            <th>종료일</th>
            <td>
              <div class="ts-date-picker-s" >
                <input type="date" class="ts-date-picker__inp-s inp-txt-common alone"  v-model="data.end">
              </div>


            </td>
          </tr>


          <tr>
            <th>모금플랫폼 주소</th>
            <td>
              <input type="text" class="inp-txt-common full" v-model="data.url" >
            </td>
          </tr>
          <tr>
            <th>주차측</th>
            <td>
              <input type="text" class="inp-txt-common full" v-model="data.company" >
            </td>
          </tr>
          <tr>
            <th>아이콘</th>
            <td>
              <div
                  ref="bob-icon"
                  id="bob-icon"
                  style="width: 32px;height: 32px;background: #F0F0F0;cursor: pointer"
                  @click="clickIcon"
              ></div>
              <file-upload-single title="" button-title="아이콘 업로드" ref="upload-icon" v-on:uploaded="uploadIcon" :hidden="true" input-id="upload-icon" />
            </td>
          </tr>
          </tbody>
        </table>
        <div class="table-foot clearfix">
          <button class="btn-confirm tf-btn" @click="back()">
            <span class="txt">취소</span>
          </button>
          <button class="btn-confirm tf-btn" id="submit" @click="save()">
            <span class="txt">저장</span>
          </button>
        </div>
      </section>
    </div>
  </div>
</template>
<style></style>
<script>
  // import Pagination from "../components/Pagination";
  // import 'codemirror/lib/codemirror.css';

  import FileUploadSingle from "@/components/FileUploadSingle";
  import CommaInput from "@/components/CommaInput.vue";

  export default {
    name: "FundSeatEdit",
    components: {CommaInput, FileUploadSingle},
    data: () => ({
      contentsType: "fund",
      contentsTitle: "배너",
      param: {
        page: 0,
        pageSize: 10,
        start: null,
        end: null,
        keyword: null
      },
      nowPage: 1,
      total: 0,
      data: {},

    }),
    mounted() {

    },
    beforeMount() {
      console.log(this.$route.params);
      this.id = this.$route.params.id ;
      console.log("this.contentsId  : " + this.id );

      if (this.id != undefined ) {
        this.getData();
      }
    },

    methods: {
      request() {

        return  this.$axios.get("fund/" + this.id );

      },
      async getData() {
        const getDataPromise = await this.request();
        const [response] = await Promise.all([getDataPromise]);

        if (response.data.success) {
          this.data = response.data.data;
          this.data.start = this.data.start.substring(0, 10);
          this.data.end = this.data.end.substring(0, 10);
          this.uploadIcon({url:this.data.icon});
        }
      },
      save() {
        var param = this.data;

        if (this.id != undefined) {
          this.$axios
                  .put("fund", param)
                  .then(response => {
                    if (response.data.success) {
                      this.$alert.show(response.data.message);
                      // this.$router.push({ path: '/fund/' + this.id});
                      this.$router.push({ path: '/fund'});

                    } else {
                      this.$alert.show(response.data.message);
                    }
                  })
                  .catch(ex => {
                    if (ex.response.data.message.constructor == Object) {
                      this.$alert.show(ex.response.data.message);
                    }
                  });
        } else {
          this.$axios
                  .post("fund", param)
                  .then(response => {
                    if (response.data.success) {
                      this.$alert.show(response.data.message);
                      this.$router.push({ path: '/fund'});

                    } else {
                      this.$alert.show(response.data.message);
                    }
                  })
                  .catch(ex => {
                    if (ex.response.data.message.constructor == Object) {
                      this.$alert.show(ex.response.data.message);
                    }
                  });
        }

      },
      back() {
        history.back();
      },
      clickIcon(){
        this.$refs["upload-icon"].openFile();
      },
      uploadIcon(param) {
        this.data.icon = param.url;
        document.getElementById("bob-icon").style.backgroundRepeat = "no-repeat";
        //이미지 등록
        document.getElementById("bob-icon").style.backgroundImage = "url('" + param.url + "')";
        //이미지 사이즈
        document.getElementById("bob-icon").style.backgroundSize = "32px";
      },


    }
  };
</script>
