<template>
  <div class="sub-container flex-sb">
    <div class="content col" style="width:14%;">
      <section class="section">
        <h2 class="page-title">컨텐츠 관리</h2>
        <div class="contents-menu-box">
          <h3 class="contents-menu-title">
            <a href="#/notice">공지사항 / 이벤트</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/banner">배너</a></h3>
          <h3 class="contents-menu-title"><a href="#/fund">모금자리</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/health-column">건강칼럼</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/faq">FAQ</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/support">지원과목 / 지원항목</a>
          </h3>
        </div>
      </section>
    </div>
    <div class="content col" style="width:86%;">
      <section class="section">
        <h3 class="section-title">{{ contentsTitle }} 상세정보</h3>
        <table class="tbl-view app-detail-info input">
          <colgroup>
            <col class="head" />
            <col class="data" />
          </colgroup>
          <tbody>
          <tr>
            <th >게시 On/Off</th>
            <td >
              <div class="t-radio-group" style="width:120px;">
                <div class="radio">
                  <input type="radio" id="radio01" name="radio" value="false" v-model="data.show">
                  <label for="radio01">숨김</label>
                </div>

                <div class="radio">
                  <input type="radio" id="radio02" name="radio"  value="true" v-model="data.show">
                  <label for="radio02">게시</label>
                </div>

              </div>
            </td>
          </tr>
          <tr>
            <th >배너이미지 (688 * 360)</th>
            <td >
              <div
                      ref="banner-image"
                      id="banner-image"
                      style="width: 360px;height: 180px;background: #F0F0F0;"
                      @click="clickUpload"
              ></div>
              <file-upload-single title="" button-title="배너 업로드" ref="upload-banner" v-on:uploaded="uploadedBanner" :hidden="true" input-id="upload-banner" />

            </td>
          </tr>

          <tr>
            <th>배너링크 URL</th>
            <td>
              <input type="text" class="inp-txt-common full" v-model="data.link" >
            </td>


          </tr>
          </tbody>
        </table>
        <div class="table-foot clearfix">
          <button class="btn-confirm tf-btn" @click="back()">
            <span class="txt">취소</span>
          </button>
          <button class="btn-confirm tf-btn" id="submit" @click="save()">
            <span class="txt">저장</span>
          </button>
        </div>
      </section>
    </div>
  </div>
</template>
<style></style>
<script>
  // import Pagination from "../components/Pagination";
  // import 'codemirror/lib/codemirror.css';
  import FileUploadSingle from '../components/FileUploadSingle'

export default {
  name: "BannerEdit",
  components: {FileUploadSingle},
  data: () => ({
    contentsType: "banner",
    contentsTitle: "배너",
    param: {
      page: 0,
      pageSize: 10,
      start: null,
      end: null,
      keyword: null
    },
    nowPage: 1,
    total: 0,
    data: {},

  }),
  mounted() {

  },
  beforeMount() {
    console.log(this.$route.params);
    this.id = this.$route.params.id ;
    console.log("this.contentsId  : " + this.id );

    if (this.id != undefined ) {
      this.getData();
    }
   },

  methods: {
    request() {

      return  this.$axios.get("banner/" + this.id );

    },
    async getData() {
      const getDataPromise = await this.request();
      const [response] = await Promise.all([getDataPromise]);

      if (response.data.success) {
        this.data = response.data.data;
        this.uploadedBanner({url: this.data.imageUrl});
        console.log(this.data);
      }
    },
    save() {
      var param = this.data;

      if (this.id != undefined) {
        this.$axios
                .put("banner", param)
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    // this.$router.push({ path: '/banner/' + this.id});
                    this.$router.push({ path: '/banner'});

                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });
      } else {
        this.$axios
                .post("banner", param)
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    this.$router.push({ path: '/banner'});

                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });
      }

    },
    back() {
      history.back();
    },
    clickUpload(){
      this.$refs["upload-banner"].openFile();
    },
    uploadedBanner(param) {
      this.data.imageUrl = param.url;
      document.getElementById("banner-image").style.backgroundRepeat = "no-repeat";
      //이미지 등록
      document.getElementById("banner-image").style.backgroundImage = "url('" + param.url + "')";
      //이미지 사이즈
      document.getElementById("banner-image").style.backgroundSize = "360px";
    },
  }
};
</script>
