<template>
  <div class="sub-container flex-sb">
    <div class="content col">
      <section class="section">
        <div class="columnn-tab">
          <a  style="cursor:pointer;" class="columnn-tab__btn " v-bind:class="{'is-active' : tabMain['info'] }" @click="selectTabMain('info')">병원 상세정보</a>
          <a  style="cursor:pointer;" class="columnn-tab__btn" v-bind:class="{'is-active' : tabMain['doctor'] }" @click="selectTabMain('doctor')">의료진 정보</a>
          <a  style="cursor:pointer;" class="columnn-tab__btn" v-bind:class="{'is-active' : tabMain['column'] }" @click="selectTabMain('column')">건강 칼럼</a>
        </div>

        <div v-if="tabMain['info']" >
          <table class="tbl-view app-detail-info">
            <colgroup>
              <col class="head" />
              <col class="data" />
              <col class="head" />
              <col class="data" />
            </colgroup>
            <tbody>
              <tr>
                <th>병원 ID</th>
                <td><span v-if="hospital.member != undefined" > {{hospital.member.email}}</span></td>
                <th>비밀번호</th>
                <td class="inp-data">
                  ********
<!--                  <button class="btn-outline pw">-->
<!--                    <span class="txt ico pw">비밀번호 재설정</span>-->
<!--                  </button>-->
                </td>
              </tr>
              <tr>
                <th>병원명</th>
                <td>{{hospital.hospitalName}}</td>
                <th>대표자</th>
                <td>{{hospital.ceoName}}</td>
              </tr>
              <tr>
                <th>전화번호</th>
                <td>{{hospital.tel}}</td>
                <th>이메일</th>
                <td>{{hospital.email}}</td>
              </tr>
              <tr>
                <th>문의채널</th>
                <td colspan="3">{{hospital.contactChannel}}</td>
              </tr>
              <tr>
                <th>주소</th>
                <td colspan="3">{{hospital.address1}} {{hospital.address2}}</td>
              </tr>
              <tr>
                <th>운영시간</th>
                <td colspan="3">
                  <span v-html="hospital.workTime1" ></span>&nbsp;
                  <span v-html="hospital.workTime2" ></span>&nbsp;
                  <span v-html="hospital.workTime3" ></span>
                </td>
              </tr>
              <tr>
                <th>진료과목</th>
                <td colspan="3">{{hospital.treatmentSubject}}</td>
              </tr>
              <tr>
                <th>의료비지원내용</th>
                <td colspan="3">
                  <div class="t-radio-group">
                    <div class="custom-checkbox" v-for="domesticMedicalCare in hospital.domesticMedicalCareList" v-bind:key="domesticMedicalCare.medicalCareId">

                      <label   >{{domesticMedicalCare.careName}}</label>
                    </div>
                  </div>
                </td>
              </tr>
<!--              <tr>-->
<!--                <th>재외동포지원내용</th>-->
<!--                <td colspan="3">-->
<!--                  <div class="t-radio-group">-->
<!--                    <div class="custom-checkbox" v-for="overseaMedicalCare in hospital.overseaMedicalCareList" v-bind:key="overseaMedicalCare.medicalCareId">-->

<!--                      <label   >{{overseaMedicalCare.careName}}</label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </td>-->
<!--              </tr>-->
              <tr>
                <th>기타안내</th>
                <td colspan="3">
                  {{hospital.etcNotification}}
                </td>
              </tr>
              <tr>
                <th>첨부서류</th>
                <td colspan="3">
                  <div class="attach-file">
                    <div class="attach-file-sub" v-for="document in hospital.documentList" v-bind:key="document.hospitalDocumentId">
                      <button  class="btn-down-file" @click="downloadItem(document.documentUrl)"><span class="txt">{{document.documentName}}</span></button>

                    </div>


                    <div class="attach-file-sub">
                      <button class="btn-down-all" @click="downloadItemAll()"><span class="txt">전체 다운로드</span></button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>보아스협력일자</th>
                <td>2020-12-11</td>
                <th>등록일자</th>
                <td>{{hospital.createdAt}}</td>
              </tr>
              <tr>
                <th>병원로고</th>
                <td>
                  <div
                          ref="hospital-logo"
                          id="hospital-logo"
                    style="width: 120px;height: 120px;background: #F0F0F0;"
                  ></div>
                </td>
                <th>병원이미지</th>
                <td>
                  <div
                          ref="hospital-image"
                          id="hospital-image"
                    style="width: 180px;height: 120px;background: #F0F0F0;"
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="table-detail-foot flex-se">
            <button class="btn-confirm tf-inp-btn"  @click="openHospitalEdit">
              <span class="txt ico apply-w">병원정보 수정하기</span>
            </button>
          </div>
        </div>
        <div v-if="tabMain['doctor']" >
          <br/>
          <div v-show="doctorMode == 'list'">
            <div class="table-util">
              <div class="util-start">
                <button class="btn-del-select" @click="deleteSelectedDoctor()">
                  <span class="txt">선택항목삭제</span>
                </button>
                <span class="us-txt">검색결과:  {{doctorList.length}}건</span>
              </div>

            </div>
            <table class="tbl-list-default other-app-info" >

              <caption>
                의료진 정보
              </caption>
              <colgroup>
                <col class="col02" />
                <col class="col02" />
                <col class="col14" />

              </colgroup>
              <thead>
              <tr>
                <th>
                  <input type="checkbox" ref="check-all" v-model="allCheckValue" value="1" id="chkAll" @click="clickAllCheckbox()" class="checkbox" />
                  <label for="chkAll" class="is-blind">전체선택</label>
                </th>
                <th scope="col">이름</th>
                <th scope="col">약력</th>
             </tr>
              </thead>
              <tbody>
              <tr
                      v-for="doctor in doctorList"
                      v-bind:key="doctor.hospitalDoctorId"

              >
                <td><input type="checkbox" :id="('checkbox_' + doctor.hospitalDoctorId)" v-model="deleteArray" :value="doctor.hospitalDoctorId" class="checkbox" /></td>
                <td @click="selectDoctor(doctor)" style="cursor: pointer">{{ doctor.name }}</td>
                <td @click="selectDoctor(doctor)" style="cursor: pointer" >{{ doctor.memo }}</td>

              </tr>
              </tbody>
            </table>

            <div class="table-detail-foot flex-se">
              <button class="btn-confirm tf-inp-btn"  @click="addDoctor()">
                <span class="txt ico apply-w">의료진 추가하기</span>
              </button>
            </div>
          </div>
          <div v-show="doctorMode == 'edit' || doctorMode == 'add' ">
            <table class="tbl-view app-detail-info input">
              <colgroup>
                <col class="head" />
                <col class="data" />
              </colgroup>
              <tbody>
              <tr>
                <th>이름</th>
                <td><input type="text" class="inp-txt-common full"   v-model="doctor.name"></td>

              </tr>
              <tr>
                <th>본문</th>
                <td>
                  <textarea class="text-area2" size="10" v-model="doctor.memo"></textarea>

                </td>

              </tr>
              </tbody>
            </table>
            <div class="table-foot clearfix">
              <button class="btn-confirm tf-btn" @click="cancelDoctor()">
                <span class="txt">취소</span>
              </button>
              <button class="btn-confirm tf-btn" @click="saveDoctor()">
                <span class="txt">저장</span>
              </button>
            </div>
          </div>
        </div>
        <div v-if="tabMain['column']" >
          <hospital-health-column
              :hospital-id="hospitalId"
              :tab-main="this.$route.query.tabMain" :tab-sub="this.$route.query.tabSub"></hospital-health-column>
        </div>
      </section>
    </div>
    <div class="content col">
      <section class="section">
        <div class="columnn-tab">
          <a style="cursor:pointer;" class="columnn-tab__btn" v-bind:class="{'is-active' : tabSub['application'] }" @click="selectTabSub('application')">지원내역</a>
<!--          <a style="cursor:pointer;" class="columnn-tab__btn" v-bind:class="{'is-active' : tabSub['calculate'] }" @click="selectTabSub('calculate')">정산내역</a>-->
          <a style="cursor:pointer;" class="columnn-tab__btn" v-bind:class="{'is-active' : tabSub['qna'] }" @click="selectTabSub('qna')">1:1문의 내역 </a>
<!--          <span class="cnt">1</span>-->
        </div>
        <div v-if="tabSub['application']" >
          <hospital-application :hospital-id="hospitalId" :tab-main="this.$route.query.tabMain" :tab-sub="this.$route.query.tabSub"></hospital-application>
        </div>
<!--        <div v-if="tabSub['calculate']" >-->
<!--          <hospital-calculate :hospital-id="hospitalId"></hospital-calculate>-->
<!--        </div>-->
        <div v-if="tabSub['qna']" >
          <member-qna ref="member-qna" :hospital-id="hospitalId"  :location="'/hispital/' + this.hospitalId"  :parent-now-page="1" :tab-main="this.$route.query.tabMain" :tab-sub="this.$route.query.tabSub"></member-qna>

        </div>

      </section>
    </div>
  </div>
</template>
<style></style>
<script>
import HospitalApplication from "../components/HospitalApplication";
// import HospitalCalculate from "../components/HospitalCalculate";
import HospitalHealthColumn from "../components/HospitalHealthColumn";
import MemberQna from "../components/MemberQna";
export default {
  name: "HospitalDetail",
  components: {MemberQna,  HospitalApplication,HospitalHealthColumn},
  data: () => ({
    hospital: {},
    status: null,
    statusContents: "",
    memoContents: "",
    memo: "",
    domesticMedicalCareList: [],
    domesticMedicalCareValue: [],
    overseaMedicalCareList: [],
    overseaMedicalCareValue: [],
    doctorList: [],
    tabMain: {info:true, doctor:false, column:false},
    tabSub: {application:true, calculate:false, qna:false},
    hospitalId : null,
    doctorParam: { hospitalId : null },
    doctorMode: "list",
    doctor: {},
    deleteArray : [],
    allCheckValue:[],
  }),
  mounted() {
    console.log(this.$route.path);
    console.log(this.$route.params.hospitalId);
    // this.$refs["member-qna"].list(null,this.hospitalId);
  },
  beforeMount() {
    this.hospitalId = this.$route.params.hospitalId;



    if (this.$route.query.tabMain != undefined) {
      const tabMain = this.$route.query.tabMain;
      if (tabMain == 'info') {
        this.tabMain = {info:true, doctor:false, column:false};
      } else if (tabMain == 'doctor') {
        this.tabMain = {info:false, doctor:true, column:false};
      } else if (tabMain == 'column') {
        this.tabMain = {info:false, doctor:false, column:true};
      }
      const tabSub = this.$route.query.tabSub;
      if (tabSub == 'application') {
        this.tabSub = {application:true, calculate:false, qna:false};
      } else if (tabSub == 'calculate') {
        this.tabSub = {application:false, calculate:true, qna:false};
      } else if (tabSub == 'qna') {
        this.tabSub = {application:false, calculate:false, qna:true};
      }
    }
    if (this.tabMain.info) {
      this.getHospital();
    }
    if (this.tabMain.doctor) {
      this.listHospitalDoctor();
    }

  },

  methods: {
    async getHospital() {
      const getHospitalPromise = await this.$axios.get(
        "hospital/" + this.$route.params.hospitalId
      );
      const [response] = await Promise.all([getHospitalPromise]);
      this.hospital = response.data.data;

      for(var i=0;i<this.hospital.domesticMedicalCareList.length;i++ ) {
        this.domesticMedicalCareValue.push(this.hospital.domesticMedicalCareList[i].careName);
      }
      for(i=0;i<this.hospital.overseaMedicalCareList.length;i++ ) {
        this.overseaMedicalCareValue.push(this.hospital.overseaMedicalCareList[i].careName);
      }



      this.uploadLogo({url:this.hospital.logoImage});
      this.uploadImage({url:this.hospital.coverImage});


    },
    selectTabMain(tabName) {
      const tabSubValue = this.tabSub.application ? 'application' : this.tabSub.calculate ? 'calculate' : this.tabSub.qna ? 'qna' : '';
      let queryParam = {};
      queryParam = {
        tabMain : tabName,
        tabSub: tabSubValue
      };
      // if (tabName == 'info') {
      //   this.tabMain = {info:true, doctor:false, column:false};
      // } else if (tabName == 'doctor') {
      //   this.tabMain = {info:false, doctor:true, column:false};
      // } else if (tabName == 'column') {
      //   this.tabMain = {info:false, doctor:false, column:true};
      // }
      this.$router.push({ path: "/hospital/" + this.hospitalId, query: queryParam });
    },
    selectTabSub(tabName) {
      const tabMainValue = this.tabMain.info ? 'info' : this.tabMain.doctor ? 'doctor' : this.tabMain.column ? 'column' : '';
      let queryParam = {};
      queryParam = {
        tabMain : tabMainValue,
        tabSub: tabName
      };
      this.$router.push({ path: "/hospital/" + this.hospitalId, query: queryParam });
      // if (tabName == 'application') {
      //   this.tabSub = {application:true, calculate:false, qna:false};
      // } else if (tabName == 'calculate') {
      //   this.tabSub = {application:false, calculate:true, qna:false};
      // } else if (tabName == 'qna') {
      //   this.tabSub = {application:false, calculate:false, qna:true};
      // }
    },
    uploadLogo(param) {
      this.hospital.logoImage = param.url;
      document.getElementById("hospital-logo").style.backgroundRepeat = "no-repeat";
      //이미지 등록
      document.getElementById("hospital-logo").style.backgroundImage = "url('" + param.url + "')";
      //이미지 사이즈
      document.getElementById("hospital-logo").style.backgroundSize = "180px";
    },
    uploadImage(param) {
      this.hospital.coverImage = param.url;
      document.getElementById("hospital-image").style.backgroundRepeat = "no-repeat";
      //이미지 등록
      document.getElementById("hospital-image").style.backgroundImage = "url('" + param.url + "')";
      //이미지 사이즈
      document.getElementById("hospital-image").style.backgroundSize = "180px";



    },
    openHospitalEdit() {
      this.$router.push({
        path: "/hospital/edit/" + this.$route.params.hospitalId
      });
    },
    downloadItem(url) {
      window.open(url);
    },
    downloadItemAll() {
      for(var i=0;i<this.hospital.documentList.length;i++) {
        window.open(this.hospital.documentList[i].documentUrl);
      }
    },
    clickAllCheckbox() {
      if (this.deleteArray.length == this.doctorList.length) {
        this.deleteArray = [];
      } else {
        this.deleteArray = [];
        for(var i=0;i<this.doctorList.length;i++) {

          this.deleteArray.push(this.doctorList[i].hospitalDoctorId) ;
        }
      }
    },
    addDoctor() {
      this.doctor = {};
      this.doctorMode = "add";
    },
    cancelDoctor() {
      this.doctorMode = "list";
    },
    saveDoctor() {
      if (this.doctorMode == 'add') {

        this.doctor.hospitalId = this.hospitalId;
        this.$axios
                .post("hospital-doctor", this.doctor)
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    // this.$router.go(0);
                    this.listHospitalDoctor();
                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });

      } else if (this.doctorMode == 'edit') {
        this.$axios
                .put("hospital-doctor", this.doctor)
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    // this.$router.go(0);
                    this.getHospital();
                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });
      }
      this.doctorMode = "list";
    },
    convertDateFromat(value) {
      if (value != undefined) {
        var resultDate = new Date(value);
        const offset = resultDate.getTimezoneOffset();
        resultDate = new Date(resultDate.getTime() - offset * 60 * 1000);
        return (
          resultDate.toISOString().split("T")[0] +
          " " +
          resultDate
            .toISOString()
            .split("T")[1]
            .substring(0, 8)
        );
      } else {
        return "";
      }
    },
    async updateStatus() {
      var param = {
        hospitalId: this.$route.params.hospitalId,
        status: this.status,
        contents: this.statusContents
      };
      this.$axios
        .post("hospital/status", param)
        .then(response => {
          if (response.data.success) {
            this.$alert.show(response.data.message);
            // this.$router.go(0);
            this.getHospital();
            this.statusContents = "";
          } else {
            this.$alert.show(response.data.message);
          }
        })
        .catch(ex => {
          if (ex.response.data.message.constructor == Object) {
            this.$alert.show(ex.response.data.message);
          }
        });
    },
    async updateMemo() {
      var param = {
        hospitalId: this.$route.params.hospitalId,
        memo: this.memoContents
      };
      this.$axios
        .post("hospital/memo", param)
        .then(response => {
          if (response.data.success) {
            this.$alert.show(response.data.message);
            // this.$router.go(0);
            this.getHospital();
            this.memoContents = "";
          } else {
            this.$alert.show(response.data.message);
          }
        })
        .catch(ex => {
          if (ex.response.data.message.constructor == Object) {
            this.$alert.show(ex.response.data.message);
          }
        });
    },
    requestList() {
      this.param.page = this.nowPage - 1;
      this.param.keyword = this.keyword;
      this.param.start = this.start;
      this.param.end = this.end;

      return this.$axios.get("hospitals", { params: this.param });
    },
    async list() {
      const getListPromise = await this.requestList();
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.hospitalData = response.data.data;
        this.total = response.data.total;
        this.$refs["pagination"].setTotal(this.total);
        console.log("#####", this.total);
      }
    },
    requestDoctorList() {
      return this.$axios.get("hospital-doctors", { params: {hospitalId : this.hospitalId} });
    },
    async listHospitalDoctor() {
      const getListPromise = await this.requestDoctorList();
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.doctorList = response.data.data;
      }
    },
    selectDoctor(doctor) {
      this.doctor = doctor;
      this.doctorMode = "edit";
    },
    deleteSelectedDoctor() {
      if (confirm("선택한 항목을 삭제하시겠습니까?")) {
        var ids = this.deleteArray.join(",");

        this.$axios
                .delete("hospital-doctor", { params: { ids : ids } })
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    this.listHospitalDoctor();
                    this.deleteArray = [];
                    this.allCheckValue = [];
                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });

      }
    },

  }
};
</script>
