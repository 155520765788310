<template>
  <div v-show="hidden != true" >
|
      <label class="btn-outline attach-doc" :for="inputId" style="box-sizing: border-box;vertical-align: middle;"
        ><span class="txt ico-attach">
        {{buttonTitle}}
      </span></label
      >
    <input
            ref="input-file"
      type="file"
      :id="inputId"
      style="display:none;"
      @change="onFileChange"
    />
  </div>
</template>

<script>
export default {
  name: "FileUploadSingle",
  props: ["buttonTitle", "inputId","hidden"],
  data() {
    return {
        url : '',
      filename: '',
    };
  },
  beforeMount() {},
  mounted() {
    this.url = this.originUrl;
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      console.log(file);
      this.upload();
    },
    openFile() {
      this.$refs["input-file"].click();
    },
    upload() {
      var frm = new FormData();
      var fileInput = document.getElementById(this.inputId);
      this.filename = fileInput.files[0].name;
      if (this.filename != '' && this.filename.indexOf('.') > 0 ) {
        this.filename = this.filename.split('.')[0];
      }
      frm.append("file", fileInput.files[0]);
      this.$axios
        .post("upload", frm, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          console.log(response.data);
          if (response.data.success) {
            var param = {
              id: this.inputId,
              url: response.data.data,
              fileName: this.filename,
            };
            this.url = response.data.data;
            this.$emit('uploaded', param);
            this.$alert.show(response.data.message);
          } else {
            this.$alert.show(response.data.message);
          }
        })
        .catch(ex => {
          this.$alert.show("에러 : " + ex);
        });
    }
  }
};
</script>

<style scoped></style>
