<template>
  <div class="sub-container flex-sb">
    <div class="content col" style="width:14%;">
      <section class="section" style="height: 100%">
        <h2 class="page-title">컨텐츠 관리</h2>
        <div class="contents-menu-box">
          <h3 class="contents-menu-title">
            <a href="#/notice">공지사항 / 이벤트</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/banner">배너</a></h3>
          <h3 class="contents-menu-title"><a href="#/fund">모금자리</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/health-column">건강칼럼</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/faq">FAQ</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/option">지원과목 / 지원항목</a>
          </h3>
        </div>
      </section>
    </div>
    <div class="content col" style="width:86%;">
      <section class="section">
        <h3 class="section-title">{{ contentsTitle }} 상세정보</h3>
        <table class="tbl-view app-detail-info">
          <colgroup>
            <col class="head" />
            <col class="data" />
            <col class="head" />
            <col class="data" />
          </colgroup>
          <tbody>
          <tr>
            <th>제목</th>
            <td v-bind:colspan="contentsType == 'notice' ? 1 : 3 ">{{data.title}}</td>
            <th v-if="contentsType == 'notice'">분류</th>
            <td v-if="contentsType == 'notice'">{{data.type}}</td>
          </tr>
          <tr>
            <th>작성자</th>
            <td>{{data.name}}</td>
            <th >작성시간</th>
            <td>{{data.createdAt}}</td>
          </tr>
          <tr>
            <th>본문</th>
            <td colspan="3"  >
              <div>
                <viewer ref="viewer" :initialValue="data.memo" height="500px" />
              </div>

            </td>

          </tr>
          </tbody>
        </table>
        <div class="table-foot clearfix">
          <button class="btn-confirm tf-btn" @click="edit()">
            <span class="txt">수정</span>
          </button>
          <button class="btn-confirm tf-btn" @click="list()">
            <span class="txt">목록</span>
          </button>
        </div>
      </section>
    </div>
  </div>
</template>
<style></style>
<script>

  import '@toast-ui/editor/dist/toastui-editor-viewer.css';
  import { Viewer } from '@toast-ui/vue-editor';
export default {
  components: {
    viewer: Viewer
  },
  name: "ContentsDetail",
  data: () => ({
    contentsType: "",
    contentsTitle: "",
    id: null,

    data: {},
  }),
  mounted() {

    console.log(this.$route.path);


  },
  beforeMount() {
    console.log(this.$route.params);
    this.id = this.$route.params.id ;
    console.log("this.contentsId  : " + this.id );
    if (this.$route.path.indexOf("/notice")>= 0 ) {
      this.contentsType = "notice";
      this.contentsTitle = "공지사항";
    } else if (this.$route.path.indexOf("/banner") >=0 ) {
      this.contentsType = "banner";
      this.contentsTitle = "배너";
    } else if (this.$route.path.indexOf("/fund") >=0 ) {
      this.contentsType = "fund";
      this.contentsTitle = "모금자리";
    } else if (this.$route.path.indexOf("/health-column") >=0 ) {
      this.contentsType = "health-column";
      this.contentsTitle = "건강칼럼";
    } else if (this.$route.path.indexOf("/faq") >= 0) {
      this.contentsType = "faq";
      this.contentsTitle = "FAQ";
    } else if (this.$route.path.indexOf("/support") >= 0) {
      this.contentsType = "support";
      this.contentsTitle = "지원과목/지원항목";
    }
    this.getData();
    if (this.data != null  && this.data != undefined && this.data.memo != undefined) {
      this.data.memo = this.data.memo.replace("\n","<br>");

    }
  },
  methods: {
    request() {

      if (this.contentsType == "notice") {
        return  this.$axios.get("notice/" + this.id  );
      } else if (this.contentsType == "banner") {
        return  this.$axios.get("banner/" + this.id );
      } else if (this.contentsType == "fund") {
        return  this.$axios.get("fund/" + this.id );
      } else if (this.contentsType == "health-column") {
        return  this.$axios.get("health-column/" + this.id );
      } else if (this.contentsType == "faq") {
        return  this.$axios.get("faq/" + this.id );
      } else if (this.contentsType == "support") {
        return  this.$axios.get("support/" + this.id );
      }
    },
    async getData() {
      const getDataPromise = await this.request();
      const [response] = await Promise.all([getDataPromise]);

      if (response.data.success) {
        this.data = response.data.data;
        this.$refs.viewer.invoke('setMarkdown', this.data.memo);

        console.log("data : ",this.data);
      }
    },
    list() {
      this.$router.back();
      // if (this.contentsType == "notice") {
      //   this.$router.push({ path: "/notice"  });
      // } else if (this.contentsType == "banner") {
      //   this.$router.push({ path: "/banner"  });
      // } else if (this.contentsType == "fund") {
      //   this.$router.push({ path: "/fund"  });
      // } else if (this.contentsType == "health-column") {
      //   this.$router.push({ path: "/health-column"  });
      // } else if (this.contentsType == "faq") {
      //   this.$router.push({ path: "/faq"  });
      // } else if (this.contentsType == "support") {
      //   this.$router.push({ path: "/support"  });
      // }
    },
    edit() {
      console.log("edit");
      if (this.contentsType == "notice") {
        this.$router.push({ path: "/notice/edit/" + this.id });
      } else if (this.contentsType == "banner") {
        this.$router.push({ path: "/banner/edit/" + this.id });
      } else if (this.contentsType == "fund") {
        this.$router.push({ path: "/fund/edit/" + this.id });
      } else if (this.contentsType == "health-column") {
        this.$router.push({ path: "/health-column/edit/" + this.id });
      } else if (this.contentsType == "faq") {
        this.$router.push({ path: "/faq/edit/" + this.id });
      } else if (this.contentsType == "support") {
        this.$router.push({ path: "/support/edit/" + this.id });
      }
    },
    del() {
      console.log("delete");
    }
  }
};
</script>
