<template>
  <div class="wrap gray">
    <header class="header">
      <div class="header-head">
        <h1 class="logo">
          <a href="#"><img src="../../public/img/logo.png" alt="보리"/></a>
        </h1>
        <span class="header-head__authority">관리자</span>
      </div>
      <nav class="gnb">
        <ul class="gnb__list clearfix">
          <li class="gnb__item">
            <a href="#/applications" class="gnb__btn" v-bind:class="{'is-active': $route.path.indexOf('/application') >= 0}">신청관리</a>
          </li>
          <li class="gnb__item">
            <a href="#/calculate" class="gnb__btn" v-bind:class="{'is-active': $route.path.indexOf('/calculate') >= 0 }">정산관리</a>
          </li>
          <li class="gnb__item">
            <a href="#/notice" class="gnb__btn" v-bind:class="{'is-active': $route.path.indexOf('/notice') >= 0 || $route.path.indexOf('/banner') >= 0  || $route.path.indexOf('/fund') >= 0 || $route.path.indexOf('/health-column') >= 0 || $route.path.indexOf('/faq') >= 0   || $route.path.indexOf('/support') >= 0 }">컨텐츠관리</a>
          </li>
          <li class="gnb__item">
            <a href="#/member" class="gnb__btn" v-bind:class="{'is-active': $route.path.indexOf('/member') >= 0 }">회원관리</a>
          </li>
          <li class="gnb__item">
            <a href="#/hospital" class="gnb__btn" v-bind:class="{'is-active': $route.path.indexOf('/hospital') >= 0}">병원관리</a>
          </li>
          <li class="gnb__item">
            <a href="#/qna" class="gnb__btn" v-bind:class="{'is-active': $route.path.indexOf('/qna') >= 0}">1:1문의</a>
          </li>
          <li class="gnb__item">
            <a href="#/setting" class="gnb__btn" v-bind:class="{'is-active': $route.path.indexOf('/setting') >= 0}">설정</a>
          </li>
        </ul>
      </nav>
      <div class="user-info">
        <span class="user-info__name">{{name}}</span>
        <button class="user-info__logout" @click="logout()">
          <span class="is-blind">로그아웃</span>
        </button>
      </div>
    </header>
    <router-view ref="main-view"  :key="$route.fullPath"></router-view>
  </div>
</template>
<style></style>

<script>
export default {
  name: "Main",
  components: {},
  data: () => ({
    nowPath: null,
    name: "",
  }),
  mounted() {
    console.log(this.$route.path);
    this.nowPath = this.$route.path;
    this.name = this.$session.get("name");
  },
  beforeMount() {
    this.$axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.$session.get("token");
  },
  methods: {
    logout() {
      this.$session.remove("token");
      this.$session.remove("name");
      this.$router.push({
        name: "Login"
      });
    }
  }
};
</script>
