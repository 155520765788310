<template>
  <div class="sub-container">
    <div class="sub-container flex-sb">
      <div class="content col">
        <section class="section">
          <h3 class="section-title">신청 상세정보</h3>
          <table class="tbl-view app-detail-info input">
            <colgroup>
              <col class="head" />
              <col class="data" />
              <col class="head" />
              <col class="data" />
            </colgroup>
            <tbody>
              <tr>
                <th>신청일</th>
                <td>{{ convertDateFromat(application.createdAt) }}</td>
                <th>신청항목</th>
                <td>
                    <select v-model="application.applicationItem">
                      <option value="의료비지원">의료비지원</option>
                      <option value="재외동포지원">재외동포지원</option>
                    </select>
                </td>
              </tr>
              <tr>
                <th>이름</th>
                <td class="inp-data">
                  <input
                    type="text"
                    class="inp-txt-common full"
                    v-model="application.name"
                  />
                </td>
                <th class="multi-line">주민번호<br />(외국인번호)</th>
                <td class="inp-data">
                  <input
                    type="text"
                    class="inp-txt-common id-num"
                    v-model="application.socialSecurityNumber"
                  />
                  <span class="bar"></span>
                  {{ application.nationality }}인{{ application.sex }}성
                </td>
              </tr>
              <tr>
                <th>휴대폰번호</th>
                <td class="inp-data">
                  <input
                    type="text"
                    class="inp-txt-common full"
                    v-model="application.mobile"
                  />
                </td>
                <th>이메일</th>
                <td class="inp-data">
                  <input
                    type="text"
                    class="inp-txt-common full"
                    v-model="application.email"
                  />
                </td>
              </tr>
              <tr>
                <th>주소</th>
                <td class="inp-data" colspan="3">
                  <div class="addr flex-row">
                    <input
                      type="text"
                      class="inp-txt-common post-num"
                      v-model="application.postcode"
                    />
                    <button class="btn-outline btn-post">
                      <span class="txt" @click="addressPopup = true"
                        >우편번호</span
                      >
                    </button>
                    <input
                      type="text"
                      class="inp-txt-common full"
                      v-model="application.address1"
                    />
                    <input
                      type="text"
                      class="inp-txt-common full"
                      v-model="application.address2"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th class="multi-line">보호자<br />(연락처)</th>
                <td class="inp-data">
                  <div class="flex-row">
                    <input
                      type="text"
                      class="inp-txt-common contact-name"
                      v-model="application.protectorName"
                    />
                    <span class="bar"></span>
                    <input
                      type="text"
                      class="inp-txt-common full"
                      v-model="application.protectorMobile"
                    />
                  </div>
                </td>
                <th>신청자와 관계</th>
                <td class="inp-data">
                  <div class="select-wrap relation">
                    <select v-model="application.protectorRelationship">
                      <option value="부">부</option>
                      <option value="모">모</option>
                      <option value="배우자">배우자</option>
                      <option value="자녀">자녀</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>기관명</th>
                <td colspan="3">
                  <input
                      type="text"
                      class="inp-txt-common full"
                      v-model="application.protectorOrganization"
                  />
                </td>
              </tr>
              <tr>
                <th>보험유형</th>
                <td class="inp-data">
                  <div class="select-wrap insurance">
                      <select v-model="application.healthInsuranceType">
                          <option value="기초생활수급자">기초생활수급자</option>
                          <option value="의료급여 1종">의료급여 1종</option>
                          <option value="의료급여 2종">의료급여 2종</option>
                          <option value="차상위계층">차상위계층</option>
                          <option value="건강보험가입자(직장)">건강보험가입자(직장)</option>
                          <option value="건강보험가입자(지역)">건강보험가입자(지역)</option>
                          <option value="건강보험가입자(외국인)">건강보험가입자(외국인)</option>
                          <option value="재외동포">재외동포</option>
                      </select>
                  </div>
                </td>
                <th>가족수 | 보험료</th>
                <td class="inp-data">
                  <div class="flex-row">
                    <div class="select-wrap relation">
                      <select v-model="application.familyCount">
                        <option value="2">2인</option>
                        <option value="3">3인</option>
                        <option value="4">4인</option>
                        <option value="5">5인</option>
                        <option value="6">6인</option>
                        <option value="7">7인</option>
                        <option value="8">8인</option>
                        <option value="9">9인</option>
                        <option value="10">10인</option>
                        <option value="11">11인</option>
                        <option value="12">12인</option>
                      </select>
                    </div>
                    <span class="bar"></span>
                    <comma-input
                        class="inp-txt-common ins-cost"
                        v-model="application.premium"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>실비보험 소지여부</th>
                <td colspan="3">
                  <div class="t-radio-group">
                    <div class="radio">
                      <input
                        type="radio"
                        id="radio01"
                        name="radio"
                        value="있음"
                        v-model="application.lifeInsuranceExist"
                      />
                      <label for="radio01">있음</label>
                    </div>
                    <div class="radio">
                      <input
                        type="radio"
                        id="radio02"
                        name="radio"
                        value="없음"
                        v-model="application.lifeInsuranceExist"
                      />
                      <label for="radio02">없음</label>
                    </div>
                    <div class="radio">
                      <input
                        type="radio"
                        id="radio03"
                        name="radio"
                        value="모름"
                        v-model="application.lifeInsuranceExist"
                      />
                      <label for="radio03">모름</label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>첨부서류</th>
                <td class="inp-data" colspan="3">
                  <div class="attach flex-row" style="margin-bottom:10px;">
                    <file-upload
                      title="주민등록등본"
                      button-title="서류첨부"
                      v-on:uploaded="changeDocument"
                      input-id="residentRegister"
                      :origin-url="application.document.residentRegister"
                    />
                    <span class="bar"></span>
                    <file-upload
                      title="건강보험납부확인서"
                      button-title="서류첨부"
                      v-on:uploaded="changeDocument"
                      input-id="healthInsurancePayments"
                      :origin-url="application.document.healthInsurancePayments"
                    />
                  </div>
                  <div class="attach flex-row" style="margin-bottom:10px;">
                    <file-upload
                      title="보험자주민등록등본"
                      button-title="서류첨부"
                      v-on:uploaded="changeDocument"
                      input-id="insurerResidentRegister"
                      :origin-url="application.document.insurerResidentRegister"
                    />
                    <span class="bar"></span>
                    <file-upload
                      title="가족관계증명서"
                      button-title="서류첨부"
                      v-on:uploaded="changeDocument"
                      input-id="familyRelationsCertificate"
                      :origin-url="
                        application.document.familyRelationsCertificate
                      "
                    />
                  </div>
                  <div class="attach flex-row" style="margin-bottom:10px;">
                    <file-upload
                      title="건강보험자격확인서"
                      button-title="서류첨부"
                      v-on:uploaded="changeDocument"
                      input-id="healthInsuranceCertificate"
                      :origin-url="
                        application.document.healthInsuranceCertificate
                      "
                    />
                    <span class="bar"></span>
                    <file-upload
                      title="기초연금수급자확인서"
                      button-title="서류첨부"
                      v-on:uploaded="changeDocument"
                      input-id="oldAgePensionerCertificate"
                      :origin-url="
                        application.document.oldAgePensionerCertificate
                      "
                    />
                  </div>
                  <div class="attach flex-row" style="margin-bottom:10px;">
                    <file-upload
                      title="기초생활수급자확인서"
                      button-title="서류첨부"
                      v-on:uploaded="changeDocument"
                      input-id="basicLivelihoodCertificate"
                      :origin-url="
                        application.document.basicLivelihoodCertificate
                      "
                    />
                    <span class="bar"></span>
                    <file-upload
                        title="차상위 계층 증명서"
                        button-title="서류첨부"
                        v-on:uploaded="changeDocument"
                        input-id="secondClassCertificate"
                        :origin-url="
                        application.document.secondClassCertificate
                      "
                    />
                  </div>
                  <div class="attach flex-row" style="margin-bottom:10px;">
                    <file-upload
                        title="여권"
                        button-title="서류첨부"
                        v-on:uploaded="changeDocument"
                        input-id="passport"
                        :origin-url="
                        application.document.passport
                      "
                    />
                    <span class="bar"></span>
                    <file-upload
                        title="외국인등록 사실증명서"
                        button-title="서류첨부"
                        v-on:uploaded="changeDocument"
                        input-id="alienRegistrationCertificate"
                        :origin-url="
                        application.document.alienRegistrationCertificate
                      "
                    />
                  </div>
                  <div class="attach flex-row" style="margin-bottom:10px;">
                    <file-upload
                        title="소득금액증명원"
                        button-title="서류첨부"
                        v-on:uploaded="changeDocument"
                        input-id="incomeAmountCertificate"
                        :origin-url="
                        application.document.incomeAmountCertificate
                      "
                    />
                    <span class="bar"></span>
                    <file-upload
                        title="추가서류"
                        button-title="서류첨부"
                        v-on:uploaded="changeDocument"
                        input-id="etc"
                        :origin-url="
                        application.document.etc
                      "
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th class="multi-line">신청병원<br />진료과목</th>
                <td class="inp-data">
                  <div class="flex-row">
                    <div class="select-wrap major">
                      <select
                        v-model="application.treatmentSubject"
                        @change="changeTreatment"
                      >
                        <option value="안과">안과</option>
                        <option value="치과">치과</option>
                        <option value="정형/신경외과">정형/신경외과</option>
                        <option value="내과">내과</option>
                        <option value="산부인과">산부인과</option>
                        <option value="이비인후과">이비인후과</option>
                        <option value="재활의학과">재활의학과</option>
                        <option value="정신건강의학과">정신건강의학과</option>
                        <option value="피부과">피부과</option>
                        <option value="화상외과">화상외과</option>
                      </select>
                    </div>
                    <div class="select-wrap major">
                      <select
                        v-model="application.hospitalId"
                        @change="changeHospital"
                      >
                        <option
                          v-for="hospital in hospitalList"
                          v-bind:key="hospital.hospitalId"
                          :value="hospital.hospitalId"
                          >{{ hospital.hospitalName }}</option
                        >
                      </select>
                    </div>
                  </div>
                </td>
                <th>지원항목</th>
                <td class="inp-data">
                  <div class="select-wrap full">
                    <select v-model="application.medicalCare">
                      <option
                        v-for="(care, index) in medicalCareList"
                        v-bind:key="index"
                        :value="care.careName"
                        >{{ care.careName }}</option
                      >
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>진료분야(증상)</th>
                <td class="inp-data" colspan="3">
                  <div class="flex-row">
                    <textarea
                      class="text-area"
                      v-model="application.medicalMemo"
                    ></textarea>
                  </div>
                </td>
              </tr>
              <tr>
                <th>생활환경 및 사연</th>
                <td class="inp-data" colspan="3">
                  <div class="flex-row">
                    <textarea
                      class="text-area"
                      v-model="application.story"
                    ></textarea>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="table-detail-foot flex-se">
            <button class="btn-outline tf-inp-btn" @click="cancel">
              <span class="txt">취소</span>
            </button>
            <button class="btn-confirm tf-inp-btn" @click="update">
              <span class="txt ico apply-w">등록하기</span>
            </button>
          </div>
          <div class="view-tail">
            <button class="btn-go-list">
              <span class="txt go-list">목록으로</span>
            </button>
          </div>
        </section>
      </div>
      <div class="content col">
        <other-application ref="other-application"
                           :application-id="application.applicationId"
                           :name="application.name"></other-application>

        <member-qna :member-id="application.memberId" :name="application.name"></member-qna>
      </div>
    </div>
    <div class="address-popup" v-if="addressPopup">
      <DaumPostcode :on-complete="handleAddress" />
    </div>
  </div>
</template>
<style></style>
<script>
import DaumPostcode from "vuejs-daum-postcode";
import OtherApplication from "../components/OtherApplication";
import MemberQna from "../components/MemberQna";

import FileUpload from "../components/FileUpload";
import CommaInput from "@/components/CommaInput.vue";
export default {
  name: "ApplicationEdit",
  components: {CommaInput, DaumPostcode, FileUpload, OtherApplication, MemberQna },

  data: () => ({
    application: {
      document: {
        residentRegister: "",
        healthInsurancePayments: "",
        insurerResidentRegister: "",
        familyRelationsCertificate: "",
        healthInsuranceCertificate: "",
        oldAgePensionerCertificate: "",
        basicLivelihoodCertificate: "",
        secondClassCertificate: "",
        passport: "",
        alienRegistrationCertificate: "",
        incomeAmountCertificate: "",
        etc: "",
        alienRegistrationCard: "",
        recommendationLetter: "",
      }
    },
    listParam: {
      page: 0,
      pageSize: 0,
      title: null
    },
    hospitalList: [],
    medicalCareList: [],
    addressPopup: false
  }),
  mounted() {
    console.log(this.$route.path);
  },
  beforeMount() {
    this.getApplication();
  },
  methods: {
    async getApplication() {
      const getApplicationPromise = await this.$axios.get(
        "application/" + this.$route.params.applicationId
      );
      const [response] = await Promise.all([getApplicationPromise]);
      this.application = response.data.data;
      console.log(this.application);
      this.status = this.application.status;
      this.changeTreatment();
      this.changeHospital();
    },
    requestHospitalList(subject) {
      this.listParam.page = 0;
      this.listParam.pageSize = 10000;
      this.listParam.treatmentSubject = subject;

      return this.$axios.get("hospitals", { params: this.listParam });
    },
    async listHospital(subject) {
      const getListPromise = await this.requestHospitalList(subject);
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.hospitalList = response.data.data;

        console.log("#####", this.total);
      }
    },
    cancel() {
      this.$router.push({
        path: "/application/" + this.application.applicationId
      });
    },
    changeTreatment() {
      console.log(this.application.treatmentSubject);
      this.hospitalList = [];
      this.listHospital(this.application.treatmentSubject);
    },
    changeHospital() {
      console.log(this.application.hospitalId);
      this.getHospital(this.application.hospitalId);
    },
    async getHospital(hospitalId) {
      const getHospitalPromise = await this.$axios.get(
        "hospital/" + hospitalId
      );
      const [response] = await Promise.all([getHospitalPromise]);
      this.hospital = response.data.data;

      if (this.application.supportType == "의료비지원") {
        this.medicalCareList = this.hospital.domesticMedicalCareList;
      } else {
        this.medicalCareList = this.hospital.overseaMedicalCareList;
      }
    },
    handleAddress(data) {
      console.log(data);
      this.application.address1 = data.roadAddress;
      this.application.area = data.sigungu;
      this.application.postcode = data.zonecode;

      this.addressPopup = false;
    },
    changeDocument(document) {
      this.application.document[document.id] = document.url;
      console.log(document);
      console.log(this.application.document);
    },
    async update() {
      var param = this.application;

      this.$axios
        .put("application", param)
        .then(response => {
          if (response.data.success) {
            this.$alert.show(response.data.message);
            this.$router.push({
              path: "/application/" + this.application.applicationId
            });
          } else {
            this.$alert.show(response.data.message);
          }
        })
        .catch(ex => {
          if (ex.response.data.message.constructor == Object) {
            this.$alert.show(ex.response.data.message);
          }
        });
    },
    convertDateFromat(value) {
      if (value != undefined) {
        var resultDate = new Date(value);
        const offset = resultDate.getTimezoneOffset();
        resultDate = new Date(resultDate.getTime() - offset * 60 * 1000);
        return (
          resultDate.toISOString().split("T")[0] +
          " " +
          resultDate
            .toISOString()
            .split("T")[1]
            .substring(0, 8)
        );
      } else {
        return "";
      }
    }
  }
};
</script>
