<template>
  <div class="sub-container">
    <div class="content">
      <h2 class="page-title">병원관리</h2>

      <div class="table-search">
        <div class="ts-sch-col">
          <input
            type="text"
            class="ts-sch-col__inp inp-txt-common"
            placeholder="병원명, 전화번호로 검색"
            v-model="keyword"
            @keyup.enter="search()"
          />
          <button class="ts-sch-col__btn" @click="search()">
            <span class="is-blind">검색하기</span>
          </button>
        </div>

        <div class="ts-select select-wrap">
          <select v-model="param.treatmentSubject" @change="search">
            <option value="">진료과목</option>
            <option value="안과">안과</option>
            <option value="치과">치과</option>
            <option value="정형/신경외과">정형/신경외과</option>
            <option value="내과">내과</option>
            <option value="산부인과">산부인과</option>
            <option value="이비인후과">이비인후과</option>
            <option value="정신건강의학과">정신건강의학과</option>
            <option value="피부성형외과">피부성형외과</option>
            <option value="소아청소년">소아청소년</option>
            <option value="화상외과">화상외과</option>
          </select>
        </div>
        <div class="ts-select select-wrap">
          <select v-model="param.status" @change="search">
            <option value="">진행상태</option>
            <option value="enable">진행중</option>
            <option value="disable">진행중단</option>
          </select>
        </div>
        <div class="ts-button">
          <button class="btn-search" @click="search()">검색하기</button>
        </div>
      </div>

      <div class="table-util">
        <div class="util-start">
          <button class="btn-del-select" @click="deleteSelected()">
            <span class="txt">선택항목삭제</span>
          </button>
          <button class="btn-down-excel" @click="downloadExcel">
            <span class="txt">엑셀다운로드</span>
          </button>

          <span class="us-txt">검색결과: 전체 {{total}}건 중 {{hospitalData.length}}건</span>
        </div>
        <div class="util-end">
          <div class="tue-select select-wrap">
            <select v-model="param.pageSize" @change="list">
              <option value="10">10개씩 보기</option>
              <option value="20">20개씩 보기</option>
              <option value="30">30개씩 보기</option>
              <option value="50">50개씩 보기</option>
            </select>
          </div>
        </div>
      </div>

      <table class="tbl-list-default application-mng">
        <colgroup>
          <col class="col01" />
          <col class="col02" />
          <col class="col03" />
          <col class="col07" />
          <col class="col14" />
          <col class="col14" />
          <col class="col07" />
          <col class="col07" />
          <col class="col13" />
          <col class="col13" />
          <col class="col13" />
        </colgroup>
        <thead>
          <tr>
            <th>
              <input type="checkbox" ref="check-all" v-model="allCheckValue" value="1" id="chkAll" @click="clickAllCheckbox()" class="checkbox" />
              <label for="chkAll" class="is-blind">전체선택</label>
            </th>
            <th>신청병원</th>
            <th>진료과목</th>
            <th>연락처</th>
            <th>주소</th>
            <th>문의채널</th>
            <th>누적지원수</th>
            <th>현재진행건수</th>
            <th>진행상태</th>
            <th>등록일</th>
            <th>비고</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="hospital in hospitalData"
            v-bind:key="hospital.hospitalId"

            style="cursor: pointer"
          >
            <td>
              <input type="checkbox" :id="('checkbox_' + hospital.hospitalId)" v-model="deleteArray" :value="hospital.hospitalId" class="checkbox" />
              <label :for="('checkbox_' + hospital.hospitalId)" class="is-blind">선택</label>
            </td>


            <td @click="openHospital(hospital.hospitalId)">{{ hospital.hospitalName }}</td>
            <td @click="openHospital(hospital.hospitalId)">{{ hospital.treatmentSubject}}</td>
            <td @click="openHospital(hospital.hospitalId)">{{ hospital.tel }}</td>
            <td @click="openHospital(hospital.hospitalId)">{{ hospital.address1 }}</td>
            <td @click="openHospital(hospital.hospitalId)">{{ hospital.contactChannel }}</td>
            <td @click="openHospital(hospital.hospitalId)">{{ hospital.cumulativeCount }}</td>
            <td @click="openHospital(hospital.hospitalId)">{{ hospital.nowCount }}</td>
            <td>
              <div class="select-wrap condition">
                <select v-model="hospital.status"  @change="changeStatus(hospital.hospitalId,hospital.status)">
                  <option value="enable">진행중</option>
                  <option value="disable">진행중단</option>
                </select>
              </div>
            </td>
            <td @click="openHospital(hospital.hospitalId)">
              {{ hospital.createdAt.substring(0, 16).replace("T", " ") }}
            </td>
            <td @click="openHospital(hospital.hospitalId)">{{ hospital.memo }}</td>

          </tr>
        </tbody>
      </table>

      <div class="table-foot clearfix">
        <Pagination
          location="hospital"
          ref="pagination"
          :page="nowPage"
          :page-size="param.pageSize"
          :treatmentSubject="param.treatmentSubject"
          :status="param.status"
          :keyword="keyword"


        ></Pagination>
        <button class="btn-confirm tf-btn" @click="newHospital()">
          <span class="txt ico apply-w">병원 등록하기</span>
        </button>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import Pagination from "../components/Pagination";
import Xlsx from "xlsx";
export default {
  name: "Hospital",
  components: { Pagination },
  data: () => ({
    keyword: null,
    start: null,
    end: null,
    insureType: null,
    hospitalType: null,
    subject: null,
    status: null,
    review: null,
    nowPage: 1,
    total: 0,
    param: {
      page: 0,
      pageSize: 10,
      start: null,
      end: null,
      keyword: null,
      treatmentSubject: "",
      status: "",
    },
    hospitalData: [],
    deleteArray : [],
    allCheckValue:[],
  }),
  mounted() {
    console.log(this.$route.path);
  },
  beforeMount() {
    if (this.$route.query.page != "" && this.$route.query.page != null) {
      this.nowPage = this.$route.query.page;
    } else {
      this.nowPage = 1;
    }

    if (this.$route.query.pageSize == undefined) {
      this.param.pageSize = 10;
    } else {
      this.param.pageSize = this.$route.query.pageSize;
    }

    if (this.$route.query.keyword != undefined) {

      this.keyword = this.$route.query.keyword;
      this.param.keyword = this.$route.query.keyword;
    }

    if (this.$route.query.status != undefined) {
      this.param.status = this.$route.query.status;
    }


    if (this.$route.query.treatmentSubject != undefined) {
      this.param.treatmentSubject = this.$route.query.treatmentSubject;
    }
    this.list();
  },
  methods: {
    openHospital(hospitalId) {
      this.$router.push({ path: "hospital/" + hospitalId });

      console.log(hospitalId);
    },
    newHospital() {
      this.$router.push({ path: "hospital/new" });
    },
    requestList() {
      this.param.page = this.nowPage - 1;
      this.param.keyword = this.keyword;
      this.param.start = this.start;
      this.param.end = this.end;

      return this.$axios.get("hospitals", { params: this.param });
    },
    search() {
      console.log("search");
      let paramName = "page";
      const page = 1;
      var queryJson = "{ \"" + paramName + "\":" + page;
      let pageSizeName = "pageSize";
      queryJson = queryJson + " ,\"" + pageSizeName + "\":" + this.param.pageSize ;
      if (this.keyword != undefined && this.keyword != null ) {
        queryJson = queryJson + " ,\"keyword\":\"" + this.keyword +"\"" ;
      }
      if (this.param.treatmentSubject != undefined && this.param.treatmentSubject != null ) {
        queryJson = queryJson + " ,\"treatmentSubject\":\"" + this.param.treatmentSubject +"\"" ;
      }


      if (this.param.status != undefined && this.param.status != null ) {
        queryJson = queryJson + " ,\"status\":\"" + this.param.status +"\"" ;
      }

      if (this.start != undefined && this.start != null ) {
        queryJson = queryJson + " ,\"start\":\"" + this.start +"\"" ;
      }

      if (this.end != undefined && this.end != null ) {
        queryJson = queryJson + " ,\"end\":\"" + this.end +"\"" ;
      }

      // if (this.treatmentSubject != undefined && this.treatmentSubject != null ) {
      //   queryJson = queryJson + " ,\"treatmentSubject\":\"" + this.treatmentSubject +"\"" ;
      // }
      //
      // if (this.optionType != undefined && this.optionType != null ) {
      //   queryJson = queryJson + " ,\"optionType\":\"" + this.optionType +"\"" ;
      // }
      //
      // if (this.parentId != undefined && this.parentId != null ) {
      //   queryJson = queryJson + " ,\"parentId\":\"" + this.parentId +"\"" ;
      // }
      queryJson = queryJson + " }";

      const queryParam = JSON.parse(queryJson);
      console.log(queryParam);
      this.$router.push({ path: "hospital", query: queryParam });
    },
    async list() {
      const getListPromise = await this.requestList();
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.hospitalData = response.data.data;
        this.total = response.data.total;
        this.$refs["pagination"].setTotal(this.total);
        console.log("#####", this.total);
      }
    },
    clickAllCheckbox() {
      if (this.deleteArray.length == this.param.pageSize) {
        this.deleteArray = [];
      } else {
        this.deleteArray = [];
        for(var i=0;i<this.data.length;i++) {

          this.deleteArray.push(this.data[i].hospitalId) ;
        }
      }
    },
    deleteSelected() {
      if (confirm("선택한 항목을 삭제하시겠습니까?")) {
        var ids = this.deleteArray.join(",");

        this.$axios
                .delete("hospital", { params: { ids : ids } })
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    this.list();
                    this.deleteArray = [];
                    this.allCheckValue = [];
                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });

      }
    },
    downloadExcel() {
      const workBook = Xlsx.utils.book_new()
      const workSheet = Xlsx.utils.json_to_sheet(this.hospitalData)
      Xlsx.utils.book_append_sheet(workBook, workSheet, '병원')
      Xlsx.writeFile(workBook, '병원리스트.xlsx')
    },
    changeStatus(hospitalId,status){
      if (confirm("선택한 신청서 상태를 변경하시겠습니까?")) {
        var param = {
          hospitalId: hospitalId,
          status: status,
        };
        this.$axios
                .post("hospital/status", param)
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    this.list();
                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });

      }
    },
  },

};
</script>
