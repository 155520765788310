<template>
  <div>
    <div class="apply" id="pdfbody" style="width:700px;">
      <div class="apply-top">
        <img src="@/assets/images/logo2.png" alt="BOAZ FOUNDATION" class="apply-logo2">
        <img src="@/assets/images/logo.png" alt="보아스 사회공헌재단"  class="apply-logo1">
      </div>
      <div class="apply-content-area">
        <div class="apply-content">
          <h1 class="apply__title">의료비 지원 신청서</h1>
          <h2 class="apply-content__name">{{application.name}}</h2>
          <table class="table">
            <tbody>
            <tr>
              <th>주민등록번호</th>
              <td>{{ application.socialSecurityNumber }}</td>
            </tr>
            <tr>
              <th>휴대폰번호</th>
              <td>{{ application.mobile }}</td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>{{ application.email }}</td>
            </tr>
            <tr>
              <th>주소</th>
              <td>{{ application.address1 }} {{ application.address2 }} ({{
                  application.postcode
                }})</td>
            </tr>
            </tbody>
          </table>
          <div class="apply-line"></div>
          <table class="table half">
            <tbody>
            <tr>
              <th>보험유형</th>
              <td>{{ application.healthInsuranceType }}</td>
              <th>희망진료분야</th>
              <td> {{ application.treatmentSubject }}</td>
            </tr>
            <tr>
              <th>보호자 성명</th>
              <td>{{ application.protectorName }}</td>
              <th>보호자 관계</th>
              <td>{{ application.protectorRelationship }}</td>
            </tr>
            <tr>
              <th>보호자 휴대폰번호</th>
              <td>{{ application.protectorMobile }}</td>
              <th>건강보험료</th>
              <td>{{ application.premium }}</td>
            </tr>
            <tr>
              <th>가족 구성원수</th>
              <td>{{ application.familyCount }}인 가족</td>
              <th>신청일자</th>
              <td>{{ convertDateFromat(application.createdAt) }}</td>
            </tr>
            </tbody>
          </table>
          <div class="apply-line"></div>
          <div class="apply-content-content">
            <p class="apply-content-title">생활환경 및 사연</p>
            <p class="apply-content__paragraph">
              {{ application.story }}
            </p>
          </div>
        </div>
        <div class="apply-bottom">
          <p class="apply-confirm">
            본 신청서는 보아스사회공헌재단 의료비지원 어플리케이션 보리에서 작성되었으며, 신청자 본인이 개인정보 수집 및 이용,<br/>
            개인정보 제3자 제공 및 행정정보 공동이용에 동의했음을 확인합니다.
          </p>
          <p class="apply-confirm2">
            사회적협동조합 보아스사회공헌재단
            <img src="@/assets/images/signature.png" alt="직인" width="48">
          </p>
          <p class="apply-info">
            (우)16953 경기도 용인시 기흥구 흥덕중앙로 55, 427호 | 대표전화 : 1661-1402 | FAX : 031-548-4552
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import "../assets/style/style.css";
</style>
<script>

import html2canvas from "html2canvas";
import 'viewerjs/dist/viewer.css'
import jsPDF from "jspdf";
export default {
  name: "ApplicationDetail",
  components: { },
  data: () => ({
    application: {},
    status: null,
    statusContents: "",
    memoContents: "",
    memo: "",
    propTitle: "mypdf"
  }),
  mounted() {

    this.makePDF();
  },
  beforeMount() {

    this.application = this.$route.params;

    // this.getApplication();

  },

  methods: {
    convertDateFromat(value) {
      if (value != undefined) {
        var resultDate = new Date(value);
        const offset = resultDate.getTimezoneOffset();
        resultDate = new Date(resultDate.getTime() - offset * 60 * 1000);
        return (
            resultDate.toISOString().split("T")[0] +
            " " +
            resultDate
                .toISOString()
                .split("T")[1]
                .substring(0, 8)
        );
      } else {
        return "";
      }
    },
    makePDF() {
      console.log("makePDF");
      window.html2canvas = html2canvas; //Vue.js 특성상 window 객체에 직접 할당해야한다.
      let that = this;
      let pdf = new jsPDF("p", "mm", "a4");
      let canvas = pdf.canvas;
      const pageWidth = 210; //캔버스 너비 mm
      // const pageHeight = 295 //캔버스 높이 mm
      canvas.width = pageWidth;

      let ele = document.querySelector("#pdfbody");
      console.log(ele);
      let width = ele.offsetWidth; // 셀렉트한 요소의 px 너비
      let height = ele.offsetHeight; // 셀렉트한 요소의 px 높이
      console.log(width + "___" + height);
      let imgHeight = (pageWidth * height) / width; // 이미지 높이값 px to mm 변환
      //let imgWidth = pageHeight * width / height;
      if (!ele) {
        console.warn("#pdf-body" + " is not exist.");
        return false;
      }
      console.log("makePDF2");
      console.log(ele);
      html2canvas(ele).then(function(canvas) {
        console.log("makePDF3");
        let position = 0;
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(
          imgData,
          "png",
          0,
          position,
          pageWidth,
          imgHeight,
          undefined,
          "slow"
        );

        // //Paging 처리
        // let heightLeft = imgHeight //페이징 처리를 위해 남은 페이지 높이 세팅.
        // heightLeft -= pageHeight
        // while (heightLeft >= 0) {
        //   position = heightLeft - imgHeight
        //   pdf.addPage();
        //   pdf.addImage(imgData, 'png', 0, position, pageWidth, imgHeight)
        //   heightLeft -= pageHeight
        // }

        pdf.save(
          that.application.name +
            "_" +
            that.application.applicationItem +
            ".pdf"
        );
      });
      console.log("end");
      console.log(pdf);
    }
  }
};
</script>
