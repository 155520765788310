<template>
  <div class="sub-container">
    <div class="content">
      <h2 class="page-title">설정</h2>


    </div>
  </div>
</template>
<style></style>
<script>
export default {
  name: "Setting",
  data: () => ({

  }),
  mounted() {},
  beforeMount() {
  },
  methods: {
  }
};
</script>
