<template>
  <div class="sub-container flex-sb">
    <div class="content col" style="width:14%;">
      <section class="section">
        <h2 class="page-title">컨텐츠 관리</h2>
        <div class="contents-menu-box">
          <h3 class="contents-menu-title">
            <a href="#/notice">공지사항 / 이벤트</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/banner">배너</a></h3>
          <h3 class="contents-menu-title"><a href="#/option">모금자리</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/health-column">건강칼럼</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/faq">FAQ</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/option">지원과목 / 지원항목</a>
          </h3>
        </div>
      </section>
    </div>
    <div class="content col" style="width:86%;">
      <section class="section">
        <h3 class="section-title">{{ contentsTitle }} 상세정보</h3>
        <table class="tbl-view app-detail-info input">
          <colgroup>
            <col class="head" />
            <col class="data" />
          </colgroup>
          <tbody>
          <tr>
            <th >타입</th>
            <td >
              <div class="t-radio-group" style="width:220px;">
                <div class="radio">
                  <input type="radio" id="radio01" name="radio" value="treatment_subject" v-model="data.optionType">
                  <label for="radio01">지원과목</label>
                </div>

                <div class="radio">
                  <input type="radio" id="radio02" name="radio"  value="medical_care" v-model="data.optionType">
                  <label for="radio02">지원항목</label>
                </div>

              </div>
            </td>
          </tr>
          <tr v-if="data.optionType == 'medical_care'">
            <th >지원타입</th>
            <td >
              <div class="t-radio-group" style="width:220px;">
                <div class="radio">
                  <input type="radio" id="radio03" name="radio2" value="domestic" v-model="data.supportType">
                  <label for="radio03">의료비지원</label>
                </div>

                <div class="radio">
                  <input type="radio" id="radio04" name="radio2"  value="oversea" v-model="data.supportType">
                  <label for="radio04">재외동포지원</label>
                </div>

              </div>
            </td>
          </tr>
          <tr v-if="data.optionType == 'medical_care'">
            <th>지원과목</th>
            <td>
              <select v-model="data.parentId">

                <option value="">진료과목</option>
                <option :value="treatment.optionId"  v-for="treatment in treatmentData" v-bind:key="treatment.optionId">{{treatment.optionName}}</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>항목명</th>
            <td>
              <input type="text" class="inp-txt-common full" v-model="data.optionName" >
            </td>
          </tr>
          <tr>
            <th>순서</th>
            <td>
              <input type="text" class="inp-txt-common full" v-model="data.order" >
            </td>
          </tr>

          </tbody>
        </table>
        <div class="table-foot clearfix">
          <button class="btn-confirm tf-btn" @click="back()">
            <span class="txt">취소</span>
          </button>
          <button class="btn-confirm tf-btn" id="submit" @click="save()">
            <span class="txt">저장</span>
          </button>
        </div>
      </section>
    </div>
  </div>
</template>
<style></style>
<script>
  // import Pagination from "../components/Pagination";
  // import 'codemirror/lib/codemirror.css';

  export default {
    name: "OptionEdit",
    data: () => ({
      contentsType: "option",
      contentsTitle: "지원과목 / 지원항목",
      param: {
        page: 0,
        pageSize: 10,
        start: null,
        end: null,
        keyword: null
      },
      nowPage: 1,
      total: 0,
      data: {},
      treatmentData:[],
      treatmentHash:{}

    }),
    mounted() {

    },
    async beforeMount() {
      console.log(this.$route.params);
      this.id = this.$route.params.id;
      console.log("this.contentsId  : " + this.id);

      await this.listTreatment();
      if (this.id != undefined) {
        this.getData();
      }
    },

    methods: {
      request() {

        return  this.$axios.get("option/" + this.id );

      },
      async getData() {
        const getDataPromise = await this.request();
        const [response] = await Promise.all([getDataPromise]);

        if (response.data.success) {
          this.data = response.data.data;

        }
      },
      save() {
        var param = this.data;

        if (this.id != undefined) {
          this.$axios
                  .put("option", param)
                  .then(response => {
                    if (response.data.success) {
                      this.$alert.show(response.data.message);
                      // this.$router.push({ path: '/option/' + this.id});
                      this.$router.push({ path: '/option'});

                    } else {
                      this.$alert.show(response.data.message);
                    }
                  })
                  .catch(ex => {
                    if (ex.response.data.message.constructor == Object) {
                      this.$alert.show(ex.response.data.message);
                    }
                  });
        } else {
          this.$axios
                  .post("option", param)
                  .then(response => {
                    if (response.data.success) {
                      this.$alert.show(response.data.message);
                      this.$router.push({ path: '/option'});

                    } else {
                      this.$alert.show(response.data.message);
                    }
                  })
                  .catch(ex => {
                    if (ex.response.data.message.constructor == Object) {
                      this.$alert.show(ex.response.data.message);
                    }
                  });
        }

      },
      back() {
        history.back();
      },
      clickUpload(){
        this.$refs["upload-option"].openFile();
      },
      treatHashSet() {
        for(var i=0;i<this.treatmentData.length;i++) {
          this.treatmentHash[this.treatmentData[i].optionId] = this.treatmentData[i].optionName;
        }
        console.log(this.treatmentHash);
      },

      async listTreatment() {
        const getTreatmentListPromise = await this.$axios.get("depth1-options",{params: { optionType : 'treatment_subject'}});
        const [response] = await Promise.all([getTreatmentListPromise]);

        if (response.data.success) {
          this.treatmentData = response.data.data;
          this.treatHashSet();

        }
        // this.treatmentArray =
      }

    }
  };
</script>
