<template>
  <table class="tbl-view app-detail-info">
    <colgroup>
      <col class="head" />
      <col class="data" />
      <col class="head" />
      <col class="data" />
    </colgroup>
    <tbody>
    <tr>
      <th>가입일</th>
      <td>{{ data.createdAt != null ? data.createdAt.substring(0, 16).replace("T", " ") : "" }}</td>
      <th>상태</th>
      <td>{{ statusMap[data.status] }}</td>
    </tr>
    <tr>
      <th>이름</th>
      <td colspan="3">{{ data.name }}</td>

    </tr>
    <tr>
      <th>휴대폰번호</th>
      <td>{{ data.mobile }}</td>
      <th>이메일</th>
      <td>{{ data.email }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "MemberDetail",
  props: [ "memberId"],
  data() {
    return {
      statusMap: {
        'before_certification': '인증전',
        'complete_verification': '인증완료',
        'withdrawal': '탈퇴',
      },
      data: {}
    };
  },
  beforeMount() {
    if (this.memberId != undefined) {
      this.getData(this.memberId);
    }

  },
  mounted() {},
  methods: {


    requestList(id) {

      return  this.$axios.get("member/" + id);
    },
    async getData(id) {
      console.log("getData");
      const getPromise = await this.requestList(id);
      const [response] = await Promise.all([getPromise]);

      if (response.data.success) {
        this.data = response.data.data;

      }
    },
  }
};
</script>

<style scoped></style>
