<template>
  <div class="pagination">
    <a
      @click="goPage(page - 1)"
      style="cursor: pointer"
      class="page-btn prev "
      v-bind:class="{ dis: isFirst }"
      ><span class="is-blind">이전페이지</span></a
    >
    <a
      @click="goPage(pageItem)"
      style="cursor: pointer"
      class="page-btn num "
      v-for="pageItem in pageArray"
      v-bind:key="pageItem"
      v-bind:class="{'is-active': page == pageItem}"
      ><span class="num-txt">{{ pageItem }}</span>
    </a>
    <a
      @click="goPage(page * 1 + 1)"
      style="cursor: pointer"
      class="page-btn next"
      v-bind:class="{ dis: isLast }"
      ><span class="is-blind">다음페이지</span></a
    >
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["page", "location","pageSize","pageParamName","pageSizeParamName" ,"keyword","insureType","applicationType","status","review","treatmentSubject","optionType","parentId","start","end","qnaType","category","answered","tabMain","tabSub","target"],
  data() {
    return {
      buttonStyle: {},
      isFirst: false,
      isLast: false,
      total: 0,
      totalPage: 0,
      pageArray: []
    };
  },
  beforeMount() {
    console.log("this.page", this.page);
    if (this.page == 1) {
      this.isFirst = true;
    }
  },
  mounted() {},
  methods: {
    goPage: function(page) {
      let paramName = "page";
      if (this.pageParamName != undefined && this.pageParamName != null ) {
        paramName = this.pageParamName;
      }
      var queryJson = "{ \"" + paramName + "\":" + page;
      let pageSizeName = "pageSize";
      if (this.pageSizeParamName != undefined && this.pageSizeParamName != null ) {
        pageSizeName = this.pageSizeParamName;
      }
      queryJson = queryJson + " ,\"" + pageSizeName + "\":" + this.pageSize ;

      if (this.keyword != undefined && this.keyword != null ) {
        queryJson = queryJson + " ,\"keyword\":\"" + this.keyword +"\"" ;
      }
      if (this.insureType != undefined && this.insureType != null ) {
        queryJson = queryJson + " ,\"insureType\":\"" + this.insureType +"\"" ;
      }

      if (this.applicationType != undefined && this.applicationType != null ) {
        queryJson = queryJson + " ,\"applicationType\":\"" + this.applicationType +"\"" ;
      }

      if (this.status != undefined && this.status != null ) {
        queryJson = queryJson + " ,\"status\":\"" + this.status +"\"" ;
      }

      if (this.review != undefined && this.review != null ) {
        queryJson = queryJson + " ,\"review\":\"" + this.review +"\"" ;
      }

      if (this.treatmentSubject != undefined && this.treatmentSubject != null ) {
        queryJson = queryJson + " ,\"treatmentSubject\":\"" + this.treatmentSubject +"\"" ;
      }

      if (this.optionType != undefined && this.optionType != null ) {
        queryJson = queryJson + " ,\"optionType\":\"" + this.optionType +"\"" ;
      }

      if (this.parentId != undefined && this.parentId != null ) {
        queryJson = queryJson + " ,\"parentId\":\"" + this.parentId +"\"" ;
      }

      if (this.start != undefined && this.start != null ) {
        queryJson = queryJson + " ,\"start\":\"" + this.start +"\"" ;
      }

      if (this.end != undefined && this.end != null ) {
        queryJson = queryJson + " ,\"end\":\"" + this.end +"\"" ;
      }
      if (this.qnaType != undefined && this.qnaType != null ) {
        queryJson = queryJson + " ,\"qnaType\":\"" + this.qnaType +"\"" ;
      }
      if (this.category != undefined && this.category != null ) {
        queryJson = queryJson + " ,\"category\":\"" + this.category +"\"" ;
      }
      if (this.answered != undefined && this.answered != null ) {
        queryJson = queryJson + " ,\"answered\":\"" + this.answered +"\"" ;
      }
      if (this.tabMain != undefined && this.tabMain != null ) {
        queryJson = queryJson + " ,\"tabMain\":\"" + this.tabMain +"\"" ;
      }
      if (this.tabSub != undefined && this.tabSub != null ) {
        queryJson = queryJson + " ,\"tabSub\":\"" + this.tabSub +"\"" ;
      }



      queryJson = queryJson + " }";
      console.log(queryJson);
      const queryParam = JSON.parse(queryJson);
      if (page > 0 && page <= this.totalPage && page != this.page) {
        this.$router.push({ path: this.location, query: queryParam });
      }

    },
    setTotal: function(value) {
      this.total = value;

      this.setPagenation();
      if (this.page == this.totalPage) {
        this.isLast = true;
      }
      console.log("-------------------------------------------");
      console.log("this.total : " + this.total);
      console.log("this.isLast : " + this.isLast);
      console.log("-------------------------------------------");
    },
    setPagenation: function() {
      this.pageArray = [];
      this.totalPage = Math.ceil(this.total / this.pageSize);

      if (this.page <= 9) {
        if (this.totalPage > 9) {
          for (var i = 1; i <= 9; i++) {
            this.pageArray.push(i);
          }
        } else {
          for (var ii = 1; ii <= this.totalPage; ii++) {
            this.pageArray.push(ii);
          }
        }
      } else if (
        this.page > 5 &&
        this.totalPage > 9 &&
        (this.totalPage - this.page) > 9
      ) {
        // console.log("22222222222");
        // console.log("totalPage",this.totalPage);
        // console.log("this.page",this.page);
        //
        // console.log(" (this.page - 4) : ", (this.page - 4));
        // console.log(" (this.page + 4) : " , (this.page + 4));
        for (var m = (this.page * 1 - 5); m < (this.page * 1 + 5); m++) {
          this.pageArray.push(m);
        }
      } else if ( (this.totalPage - this.page ) <= 9) {
         //  console.log("33333333333");
         // console.log("totalPage",this.totalPage);
         // console.log("this.page",this.page);

        for (var j = this.totalPage - 9; j <= this.totalPage; j++) {
          this.pageArray.push(j);
        }
      }
    }
  }
};
</script>

<style scoped></style>
