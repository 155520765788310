<template>
  <div>
    <div class="table-search sub">
      <div class="ts-row">
        <div class="ts-sch-col">
          <input
                  type="text"
                  class="ts-sch-col__inp inp-txt-common"
                  placeholder="이름, 휴대폰번호 검색"
                  v-model="keyword"
                  @keyup.enter="list()"
          />
          <button class="ts-sch-col__btn" @click="list()">
            <span class="is-blind">검색하기</span>
          </button>
        </div>
        <div class="ts-date-picker">
          <input
                  type="text"
                  class="ts-date-picker__inp inp-txt-common from"
                  placeholder="부터"
                  v-model="start"
          />
          <input
                  type="text"
                  class="ts-date-picker__inp inp-txt-common to"
                  placeholder="까지"
                  v-model="end"
          />
        </div>
      </div>
      <div class="ts-row">
        <div class="ts-select select-wrap">
          <select v-model="param.insureType" @change="list">
            <option value="">보험유형</option>
            <option value="건강보험가입자(직장)">직장 건강보험가입자</option>
            <option value="지역 건강보험가입자">지역 건강보험가입자</option>
          </select>
        </div>
        <div class="ts-select select-wrap">
          <select v-model="param.applicationType" @change="list">
            <option value="">신청항목</option>
            <option value="의료비지원">의료비지원</option>
            <option value="재외동포지원">재외동포지원</option>
          </select>
        </div>
        <div class="ts-select select-wrap">
          <select v-model="param.status" @change="list">
            <option value="">진행상황</option>
            <option value="received">접수</option>
            <option value="selected">선정</option>
            <option value="recheck">재확인</option>
            <option value="review">심의</option>
            <option value="drop">탈락</option>
            <option value="cancel">신청포기</option>
            <option value="treatment_progress">치료진행</option>
            <option value="treatment_completed">치료완료</option>


          </select>
        </div>
        <div class="ts-select select-wrap">
          <select v-model="param.review" @change="list">
            <option value="">심의여부</option>
            <option value="true">심의</option>
            <option value="false">비심의</option>
          </select>
        </div>
        <div class="ts-button">
          <button class="btn-search"  @click="list()">검색하기</button>
        </div>
      </div>
    </div>

    <table class="tbl-list-default other-app-info">
      <caption>
        회원의 다른 신청정보
      </caption>
      <colgroup>
        <col class="col01" />
        <col class="col02" />
        <col class="col03" />
        <col class="col04" />
        <col class="col05" />
        <col class="col06" />
        <col class="col07" />
      </colgroup>
      <thead>
      <tr>
        <th scope="col">신청일</th>
        <th scope="col">신청자(관계)</th>
        <th scope="col">생년월일</th>
        <th scope="col">보험유형</th>
        <th scope="col">가족수/건강보험료</th>
        <th scope="col">진료과목/지원항목</th>
        <th scope="col">진행상태</th>
      </tr>
      </thead>
      <tbody>
      <tr
              v-for="application in applicationData"
              v-bind:key="application.applicationId"
              style="cursor: pointer"
      >
        <td>{{ application.createdAt.substring(0, 16).replace("T", " ") }}</td>
        <td>{{ application.name }}</td>
        <td><span v-if="application.socialSecurityNumber != null && application.socialSecurityNumber.length >= 6" >{{ application.socialSecurityNumber.substring(0, 6) }}</span></td>
        <td>{{application.healthInsuranceType}}</td>
        <td>
          {{ application.familyCount }} | {{ application.premium }} 원
        </td>
        <td>{{ application.treatmentSubject }}<br />{{ application.medicalCare }}</td>
<!--        <td>-->
<!--          <span v-if="application.hospital != undefined" >{{ application.hospital.hospitalName }}</span>-->
<!--        </td>-->
        <td>{{application.status}}</td>
      </tr>
      </tbody>
    </table>

    <div class="table-foot clearfix">
      <Pagination :location="$route.path" ref="pagination"
                  :keyword="param.keyword"
                  :start="param.start"
                  :end="param.end"
                  :insureType="param.insureType"
                  :applicationType="param.applicationType"
                  :status="param.status"
                  :review="param.review"
                  :page="nowPage"
                  :tab-main="param.tabMain"
                  :tab-sub="param.tabSub"
                  page-param-name="haPage"
                  page-size-param-name="haPageSize"
                  :extra-param-values="'\'page\':' + parentNowPage +     ',\'pageSize\':' + $route.query.pageSize"

                  :page-size="10"></Pagination>
    </div>
  </div>
</template>

<script>
  import Pagination from "../components/Pagination";

  export default {
  name: "HospitalApplication",
    props: [ "hospitalId","tabMain","tabSub","parentNowPage"],
  components: {Pagination},
  data() {
    return {
      keyword: null,
      start: null,
      end: null,
      insureType: null,
      applicationType: null,
      subject: null,
      status: null,
      review: null,
      nowPage: 1,
      total: 0,
      location: "",
      param: {
        page: 0,
        pageSize: 10,
        start: null,
        end: null,
        keyword: null,
        hospitalId: this.hospitalId,
        tabMain: this.tabMain,
        tabSub: this.tabSub,
        insureType: "",
        applicationType: "",
        status: "",
        review: "",
      },
      applicationData: []
    };
  },
  beforeMount() {
    if (this.$route.query.haPage != '' && this.$route.query.haPage != null) {
      this.nowPage = this.$route.query.haPage;
    } else {
      this.nowPage = 1;
    }
    this.location = "/hospital/" + this.hospitalId;
    this.list();
  },
  mounted() {},
  methods: {
    requestList() {
      this.param.page = this.nowPage -1;
      this.param.keyword = this.keyword;
      this.param.start = this.start;
      this.param.end = this.end;

      return  this.$axios.get("hospital-applications", { params: this.param });
    },
    async list() {
      const getListPromise = await this.requestList();
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.applicationData = response.data.data;
        this.total = response.data.total;
        this.$refs["pagination"].setTotal(this.total);
        console.log("#####",this.total);
      }
    },
  }
};
</script>

<style scoped></style>
