<template>
  <div>
    <div v-show="mode == 'list'">
      <div class="table-search">
        <div class="ts-sch-col">
          <input
            type="text"
            class="ts-sch-col__inp inp-txt-common"
            placeholder="제목, 본문 검색"
            v-model="keyword"
            @keyup.enter="list()"
          />
          <button class="ts-sch-col__btn" @click="list()">
            <span class="is-blind">검색하기</span>
          </button>
        </div>

        <div class="ts-button">
          <button class="btn-search" @click="list()">검색하기</button>
        </div>
      </div>
      <div class="table-util">
        <div class="util-start">
          <button class="btn-del-select" @click="deleteSelected()">
            <span class="txt">선택항목삭제</span>
          </button>
          <span class="us-txt">검색결과: {{ total }}건</span>
        </div>
        <div class="util-end">
          <div class="tue-select select-wrap">
            <select v-model="param.pageSize" @change="list">
              <option value="10">10개씩 보기</option>
              <option value="20">20개씩 보기</option>
              <option value="30">30개씩 보기</option>
              <option value="50">50개씩 보기</option>
            </select>
          </div>
        </div>
      </div>
      <table class="tbl-list-default application-mng">
        <colgroup>
          <col class="col01" />
          <col class="col15" />
          <col class="col09" />
          <col class="col14" />
          <col class="col08" />
        </colgroup>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                ref="check-all"
                v-model="allCheckValue"
                value="1"
                id="chkAll"
                @click="clickAllCheckbox()"
                class="checkbox"
              />
              <label for="chkAll" class="is-blind">전체선택</label>
            </th>
            <th>번호</th>
            <th>제목</th>
            <th>내용</th>
            <th>등록일</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in listData"
            v-bind:key="row.hospitalHealthColumnId"
            style="cursor: pointer"
          >
            <td>
              <input
                type="checkbox"
                :id="'checkbox_' + row.hospitalHealthColumnId"
                v-model="deleteArray"
                :value="row.hospitalHealthColumnId"
                class="checkbox"
              />
              <label
                :for="'checkbox_' + row.hospitalHealthColumnId"
                class="is-blind"
                >선택</label
              >
            </td>
            <td @click="open(row.hospitalHealthColumnId)">
              {{ row.hospitalHealthColumnId }}
            </td>
            <td @click="open(row.hospitalHealthColumnId)">{{ row.title }}</td>
            <td
              style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;"
              @click="open(row.hospitalHealthColumnId)"
            >
              {{ row.memo | stripHTML }}
            </td>
            <td @click="open(row.hospitalHealthColumnId)">
              {{ row.createdAt.substring(0, 16).replace("T", " ") }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-foot clearfix">
        <Pagination
          :location="$router.path"
          ref="pagination"
          :page="nowPage"
          :page-size="param.pageSize"
          :tab-main="tabMain"
          :tab-sub="tabSub"
        ></Pagination>
        <button class="btn-confirm tf-btn" @click="add()">
          <span class="txt ico apply-w">등록</span>
        </button>
      </div>
    </div>
    <div v-show="mode == 'view'">
      <table class="tbl-view app-detail-info">
        <colgroup>
          <col class="head" />
          <col class="data" />
          <col class="head" />
          <col class="data" />
        </colgroup>
        <tbody>
          <tr>
            <th>제목</th>
            <td >
              {{ data.title }}
            </td>
              <th>작성시간</th>
              <td>{{ data.createdAt }}</td>
          </tr>
          <tr>
            <th>본문</th>
            <td colspan="3">
              <div>
                <viewer ref="viewer" :initialValue="data.memo" height="500px" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-foot clearfix">
        <button class="btn-confirm tf-btn" @click="goEdit()">
          <span class="txt">수정</span>
        </button>
        <button class="btn-confirm tf-btn" @click="goList()">
          <span class="txt">목록</span>
        </button>
      </div>
    </div>
    <div v-show="mode == 'edit' || mode == 'add'">
      <table class="tbl-view app-detail-info input">
        <colgroup>
          <col class="head" />
          <col class="data" />
        </colgroup>
        <tbody>
          <tr>
            <th>제목</th>
            <td >
              <input
                type="text"
                class="inp-txt-common full"
                v-model="data.title"
              />
            </td>

          </tr>
          <tr>
            <th>본문</th>
            <td  >
              <!--              <div ref="toastEditor" id="toastEditor"></div>-->
              <editor
                ref="toastEditor"
                :initialValue="data.memo"
                :options="editorOptions"
                height="500px"
                initialEditType="wysiwyg"
                previewStyle="vertical"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-foot clearfix">
        <button class="btn-confirm tf-btn" @click="back()">
          <span class="txt">취소</span>
        </button>
        <button class="btn-confirm tf-btn" id="submit" @click="save()">
          <span class="txt">저장</span>
        </button>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import Pagination from "../components/Pagination";
import '@toast-ui/editor/dist/toastui-editor.css';
import axios from "axios";
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer,Editor } from '@toast-ui/vue-editor';
export default {
  name: "HospitalHealthColumn",
  props: ["hospitalId","tabMain","tabSub"],
  components: { Pagination,viewer: Viewer ,editor:Editor},
  data: () => ({
    param: {
      page: 0,
      pageSize: 10,
      start: null,
      end: null,
      keyword: null,
      hospitalId: null,
    },
    keyword: null,
    start: null,
    end: null,
    nowPage: 1,
    total: 0,
    listData: [],
      data: {},
    deleteArray: [],
    allCheckValue: [],
    mode: "list",
      hospitalColumnId: null,
      editorOptions: {
          hideModeSwitch: true,
          hooks: {
              addImageBlobHook: (blob, callback) => {
                  (async () => {
                      const formData = new FormData();
                      formData.append('file', blob);
                      const { data } = await  axios.post(process.env.VUE_APP_BASE_URL + 'upload/image',
                          formData,
                          {
                              headers: {
                                  'Content-Type': 'multipart/form-data',
                              },
                          }
                      );
                      // if (this.data.imageUrl == null || this.data.imageUrl == null) {
                      //   this.data.imageUrl = data;
                      // }
                      console.log(data.data);
                      callback(data.data, "iamge");
                  })();
                  return false;
              }
          }
      }
  }),
  mounted() {
    if (this.$route.query.pageSize == undefined) {
      this.param.pageSize = 10;
    } else {
      this.param.pageSize = this.$route.query.pageSize;
    }
  },
  beforeMount() {
    if (this.$route.query.page != "" && this.$route.query.page != null) {
      this.nowPage = this.$route.query.page;
    } else {
      this.nowPage = 1;
    }
    this.list();
  },

  methods: {

    request() {
      console.log("this.hospitalId : " + this.hospitalId);
      this.param.page = this.nowPage - 1;
      this.param.keyword = this.keyword;
      this.param.start = this.start;
      this.param.end = this.end;
      this.param.hospitalId = this.hospitalId;
      return this.$axios.get("hospital-columns", { params: this.param });
    },
    async list() {
      const getListPromise = await this.request();
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.listData = response.data.data;
        this.total = response.data.total;
        this.$refs["pagination"].setTotal(this.total);
        console.log("#####", this.total);
      }
    },
    clickAllCheckbox() {
      if (this.deleteArray.length == this.param.pageSize) {
        this.deleteArray = [];
      } else {
        this.deleteArray = [];
        for (var i = 0; i < this.listData.length; i++) {
          this.deleteArray.push(this.listData[i].id);
        }
      }
    },
    deleteSelected() {
      if (confirm("선택한 항목을 삭제하시겠습니까?")) {
        var ids = this.deleteArray.join(",");

        this.$axios
          .delete("hospital-column", { params: { ids: ids } })
          .then(response => {
            if (response.data.success) {
              this.$alert.show(response.data.message);
              this.list();
              this.deleteArray = [];
              this.allCheckValue = [];
            } else {
              this.$alert.show(response.data.message);
            }
          })
          .catch(ex => {
            if (ex.response.data.message.constructor == Object) {
              this.$alert.show(ex.response.data.message);
            }
          });
      }
    },
    requestData() {
      return this.$axios.get("hospital-column/" + this.hospitalColumnId);
    },
    async getData() {
      const getDataPromise = await this.requestData();
      const [response] = await Promise.all([getDataPromise]);

      if (response.data.success) {
        this.data = response.data.data;
        this.$refs.viewer.invoke("setMarkdown", this.data.memo);

        console.log("data : ", this.data);
      }
    },
      goList() {
        this.mode = "list"
      },
      goEdit() {
        this.$refs.toastEditor.invoke('setMarkdown', this.data.memo);
        this.mode = "edit";
      },
      add() {
        this.hospitalColumnId = null;
        this.mode = "add";
      },
      open(id) {
          this.hospitalColumnId = id;

          this.getData();

          this.mode = "view";
      },
    save() {

      this.data.memo = this.$refs.toastEditor.invoke('getHTML');

      var param = this.data;


      var re = new RegExp(/src="(.[^"]+)"/,"g");
      const srcList = re.exec(this.data.memo);
      if (srcList != null && srcList.length > 1) {
        param.image = srcList[1];
        console.log(param.imageUrl );
      }


      if (this.hospitalColumnId != undefined) {
        this.$axios
                .put("hospital-column", param)
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    this.getData();
                    this.mode="view";

                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });
      } else {
        param.hospitalId = this.hospitalId;
        this.$axios
                .post("hospital-column", param)
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    //this.$router.push({ path: '/hospital/' + this.hospital.hospitalId});
                    this.list();
                    this.mode = "list";

                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });
      }

    },
    back() {
      history.back();
    },

  }
};
</script>
