<template>
  <div class="sub-container flex-sb">
    <div class="content col" style="width:14%;">
      <section class="section">
        <h2 class="page-title">컨텐츠 관리</h2>
        <div class="contents-menu-box">
          <h3 class="contents-menu-title">
            <a href="#/notice">공지사항 / 이벤트</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/banner">배너</a></h3>
          <h3 class="contents-menu-title"><a href="#/option">모금자리</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/health-column">건강칼럼</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/faq">FAQ</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/option">지원과목 / 지원항목</a>
          </h3>
        </div>
      </section>
    </div>
    <div class="content col" style="width:86%;">
      <section class="section">
        <h3 class="section-title" style="margin-top:24px;">
          {{ contentsTitle }}
        </h3>
        <div class="table-search">
          <div class="ts-sch-col">
            <input
                    type="text"
                    class="ts-sch-col__inp inp-txt-common"
                    placeholder="지원과목/지원항목 검색"
                    v-model="param.keyword"
                    @keyup.enter="list()"
            />
            <button class="ts-sch-col__btn">
              <span class="is-blind">검색하기</span>
            </button>
          </div>
          <div class="ts-select select-wrap">
            <select v-model="param.optionType" @change="list">
              <option value="">옵션 타입</option>
              <option value="treatment_subject">지원과목</option>
              <option value="medical_care">지원항목</option>
            </select>
          </div>
          <div class="ts-select select-wrap">

            <select v-model="param.parentId" @change="list">

              <option value="">진료과목</option>
              <option :value="treatment.optionId"  v-for="treatment in treatmentData" v-bind:key="treatment.optionId">{{treatment.optionName}}</option>
            </select>
          </div>

          <div class="ts-button">
            <button class="btn-search" @click="list()">검색하기</button>
          </div>
        </div>
        <div style="padding-top: 36px;"></div>
        <div class="table-util">
          <div class="util-start">
            <button class="btn-del-select" @click="deleteSelected()">
              <span class="txt">선택항목삭제</span>
            </button>
            <span class="us-txt">검색결과: {{ total }}건</span>
          </div>
          <div class="util-end">
            <div class="tue-select select-wrap">
              <select v-model="param.pageSize" @change="list">
                <option value="10">10개씩 보기</option>
                <option value="20">20개씩 보기</option>
                <option value="30">30개씩 보기</option>
                <option value="50">50개씩 보기</option>
              </select>
            </div>
          </div>
        </div>
        <table class="tbl-list-default application-mng">
          <colgroup>
            <col class="col01" />
            <col class="col02" />
            <col class="col11" />
            <col class="col13" />
            <col class="col02" />
            <col class="col11" />
            <col class="col11" />
          </colgroup>
          <thead>
          <tr>
            <th>
              <input
                      type="checkbox"
                      ref="check-all"
                      v-model="allCheckValue"
                      value="1"
                      id="chkAll"
                      @click="clickAllCheckbox()"
                      class="checkbox"
              />
              <label for="chkAll" class="is-blind">전체선택</label>
            </th>
            <th>번호</th>
            <th>타입</th>
            <th>지원타입</th>
            <th>지원과목</th>
            <th>지원과목/지원항목</th>
            <th>순서</th>
            <th>등록일</th>
          </tr>
          </thead>
          <tbody>
          <tr
                  v-for="row in data"
                  v-bind:key="row.optionId"
                  style="cursor: pointer"
          >
            <td>
              <input
                      type="checkbox"
                      :id="'checkbox_' + row.optionId"
                      v-model="deleteArray"
                      :value="row.optionId"
                      class="checkbox"
              />
              <label :for="'checkbox_' + row.optionId" class="is-blind"
              >선택</label
              >
            </td>
            <td @click="open(row.optionId)">
              {{ row.optionId }}
            </td>

            <td @click="open(row.optionId)">
              {{ row.optionType == 'treatment_subject' ? "지원과목" : "지원항목" }}
            </td>
            <td @click="open(row.optionId)">
              {{ row.supportType == 'oversea' ? "재외동포지원" : ""}}
              {{ row.supportType == 'domestic' ? "의료비지원" : "" }}


            </td>
            <td @click="open(row.optionId)">
              {{ row.parentId != null ? treatmentHash[row.parentId] : "" }}
            </td>
            <td @click="open(row.optionId)">
              {{ row.optionName }}
            </td>
            <td  @click="open(row.optionId)">{{ row.order}}</td>
            <td @click="open(row.optionId)">
              {{ row.createdAt.substring(0, 16).replace("T", " ") }}
            </td>
          </tr>
          </tbody>
        </table>
        <div class="table-foot clearfix">
          <Pagination
                  :location="$route.path"
                  ref="pagination"
                  :page="nowPage"
                  :page-size="param.pageSize"
                  :keyword = "param.keyword"
                  :option-type="param.optionType"
                  :parent-id="param.parentId"

          ></Pagination>
          <button class="btn-confirm tf-btn" @click="add()">
            <span class="txt ico apply-w">{{ contentsTitle }} 등록</span>
          </button>
        </div>
      </section>
    </div>
  </div>
</template>
<style></style>
<script>
  import Pagination from "../components/Pagination";
  export default {
    name: "Option",
    components: { Pagination },
    data: () => ({
      contentsType: "option",
      contentsTitle: "지원과목/지원항목",
      param: {
        page: 0,
        pageSize: 10,
        start: null,
        end: null,
        keyword: null,
        parentId: "",
        optionType:"",
      },
      keyword: null,
      start: null,
      end: null,
      nowPage: 1,
      total: 0,
      data: [],
      deleteArray: [],
      allCheckValue: [],
      treatmentData:[],
      treatmentHash:{}
    }),
    mounted() {

    },
    async beforeMount() {
      this.contentsTitle = "지원과목 / 지원항목" +
              "";

      if (this.$route.query.page != "" && this.$route.query.page != null) {
        this.nowPage = this.$route.query.page;
      } else {
        this.nowPage = 1;
      }

      if (this.$route.query.pageSize == undefined) {
        this.param.pageSize = 10;
      } else {
        this.param.pageSize = this.$route.query.pageSize;
      }

      if (this.$route.query.keyword != undefined) {
        this.param.keyword = this.$route.query.keyword;
      }

      if (this.$route.query.optionType != undefined) {
        this.param.optionType = this.$route.query.optionType;
      }

      if (this.$route.query.parentId != undefined) {
        this.param.parentId = this.$route.query.parentId;
      }




      await this.listTreatment();
      await this.list();
    },

    methods: {
      add() {
        this.$router.push({ path: "option/new" });
      },
      open(id) {
        this.$router.push({ path: "option/edit/" + id });
      },
      request() {
        this.param.page = this.nowPage - 1;
        return this.$axios.get("options", { params: this.param });
      },
      async list() {
        const getListPromise = await this.request();
        const [response] = await Promise.all([getListPromise]);

        if (response.data.success) {

          console.log("response.data.data",response.data.data);
          // for(let i=0;i<response.data.data.length;i++) {
          //   console.log(response.data.data[i].optionType);
          //   if (response.data.data[i].optionType == 'treatment_subject') {
          //     this.treatmentSubjectMap[response.data.data[i].optionId] = response.data.data[i].optionName;
          //   }
          // }
          console.log("treatmentSubjectMap",this.treatmentSubjectMap);
          this.data = response.data.data;
          this.total = response.data.total;
          this.$refs["pagination"].setTotal(this.total);

          console.log("#####", this.total);
        }
      },
      clickAllCheckbox() {
        if (this.deleteArray.length == this.param.pageSize) {
          this.deleteArray = [];
        } else {
          this.deleteArray = [];
          for (var i = 0; i < this.data.length; i++) {
            this.deleteArray.push(this.data[i].optionId);
          }
        }
      },
      deleteSelected() {
        if (confirm("선택한 항목을 삭제하시겠습니까?")) {
          var ids = this.deleteArray.join(",");

          this.$axios
                  .delete("option", { params: { ids: ids } })
                  .then(response => {
                    if (response.data.success) {
                      this.$alert.show(response.data.message);
                      this.list();
                      this.deleteArray = [];
                      this.allCheckValue = [];
                    } else {
                      this.$alert.show(response.data.message);
                    }
                  })
                  .catch(ex => {
                    if (ex.response.data.message.constructor == Object) {
                      this.$alert.show(ex.response.data.message);
                    }
                  });
        }
      },
      treatHashSet() {
        for(var i=0;i<this.treatmentData.length;i++) {
          this.treatmentHash[this.treatmentData[i].optionId] = this.treatmentData[i].optionName;
        }
        console.log(this.treatmentHash);
      },

      async listTreatment() {
        const getTreatmentListPromise = await this.$axios.get("depth1-options",{params: { optionType : 'treatment_subject'}});
        const [response] = await Promise.all([getTreatmentListPromise]);

        if (response.data.success) {
          this.treatmentData = response.data.data;
          this.treatHashSet();

        }
        // this.treatmentArray =
      }
    }
  };
</script>


