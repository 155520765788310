<template>
  <div class="sub-container flex-sb">
    <div class="content col" style="width:14%;">
      <section class="section">
        <h2 class="page-title">컨텐츠 관리</h2>
        <div class="contents-menu-box">
          <h3 class="contents-menu-title">
            <a href="#/notice">공지사항 / 이벤트</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/banner">배너</a></h3>
          <h3 class="contents-menu-title"><a href="#/fund">모금자리</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/health-column">건강칼럼</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/faq">FAQ</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/support">지원과목 / 지원항목</a>
          </h3>
        </div>
      </section>
    </div>
    <div class="content col" style="width:86%;">
      <section class="section">
        <h3 class="section-title" style="margin-top:24px;">
          {{ contentsTitle }}
        </h3>
        <div style="padding-top: 36px;">
        </div>
        <div class="table-util">
          <div class="util-start">
            <button class="btn-del-select" @click="deleteSelected()">
              <span class="txt">선택항목삭제</span>
            </button>
            <span class="us-txt">검색결과:  {{total}}건</span>
          </div>
          <div class="util-end">
            <div class="tue-select select-wrap">
              <select v-model="param.pageSize" @change="list">
                <option value="10">10개씩 보기</option>
                <option value="20">20개씩 보기</option>
                <option value="30">30개씩 보기</option>
                <option value="50">50개씩 보기</option>
              </select>
            </div>
          </div>
        </div>
        <table class="tbl-list-default application-mng">
          <colgroup>
            <col class="col01" />
            <col class="col01" />
            <col class="col15" />
            <col class="col07" />
            <col class="col13" />
            <col class="col16" />
            <col class="col16" />
          </colgroup>
          <thead>
          <tr>
            <th>
              <input type="checkbox" ref="check-all" v-model="allCheckValue" value="1" id="chkAll" @click="clickAllCheckbox()" class="checkbox" />
              <label for="chkAll" class="is-blind">전체선택</label>
            </th>
            <th>번호</th>
            <th>게시 On/Off</th>
            <th>배너이미지(688 * 360)</th>
            <th>배너링크 URL</th>
            <th>등록자</th>
            <th>등록일</th>
          </tr>
          </thead>
          <tbody>
          <tr
                  v-for="row in data"
                  v-bind:key="row.bannerId"

                  style="cursor: pointer"
          >
            <td>
              <input type="checkbox" :id="('checkbox_' + row.bannerId)" v-model="deleteArray" :value="row.bannerId" class="checkbox" />
              <label :for="('checkbox_' + row.bannerId)" class="is-blind">선택</label>
            </td>
            <td  @click="open(row.bannerId)">
              {{ row.bannerId }}
            </td>

            <td  @click="open(row.bannerId)">{{ row.show == true ? "게시":"숨김"}}</td>
            <td  @click="open(row.bannerId)"><img :src="row.imageUrl" width="360" height="180" /></td>
            <td style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;" @click="open(row.bannerId)">{{row.link}}</td>
            <td  @click="open(row.bannerId)">{{ row.name}}</td>
            <td  @click="open(row.bannerId)">{{ row.createdAt.substring(0, 16).replace("T", " ") }}</td>
          </tr>
          </tbody>
        </table>
        <div class="table-foot clearfix">
          <Pagination
                  location="notice"
                  ref="pagination"
                  :page="nowPage"
                  :page-size="param.pageSize"
          ></Pagination>
          <button class="btn-confirm tf-btn" @click="add()">
            <span class="txt ico apply-w">{{ contentsTitle }} 등록</span>
          </button>
        </div>
      </section>
    </div>
  </div>
</template>
<style></style>
<script>
  import Pagination from "../components/Pagination";
  export default {
    name: "Banner",
    components: {Pagination},
    data: () => ({
      contentsType: "notice",
      contentsTitle: "공지사항",
      param: {
        page: 0,
        pageSize: 10,
        start: null,
        end: null,
        keyword: null
      },
      keyword: null,
      start:null,
      end:null,
      nowPage: 1,
      total: 0,
      data: [],
      deleteArray : [],
      allCheckValue:[],
    }),
    mounted() {

      if (this.$route.query.pageSize == undefined) {
        this.param.pageSize = 10 ;
      } else {
        this.param.pageSize = this.$route.query.pageSize;
      }
    },
    beforeMount() {
      this.contentsType = "banner";
      this.contentsTitle = "배너";

      if (this.$route.query.page != '' && this.$route.query.page != null) {
        this.nowPage = this.$route.query.page;
      } else {
        this.nowPage = 1;
      }
      this.list();
    },

    methods: {
      add() {
        this.$router.push({ path: "banner/new" });
      },
      open(id) {
        this.$router.push({ path: "banner/edit/" + id });
      },
      request() {
        this.param.page = this.nowPage -1;
        this.param.keyword = this.keyword;
        this.param.start = this.start;
        this.param.end = this.end;
        return  this.$axios.get("banners", { params: this.param });
      },
      async list() {
        const getListPromise = await this.request();
        const [response] = await Promise.all([getListPromise]);

        if (response.data.success) {
          this.data = response.data.data;
          this.total = response.data.total;
          this.$refs["pagination"].setTotal(this.total);
          console.log("#####",this.total);
        }
      },
      clickAllCheckbox() {
        if (this.deleteArray.length == this.param.pageSize) {
          this.deleteArray = [];
        } else {
          this.deleteArray = [];
          for(var i=0;i<this.data.length;i++) {

            this.deleteArray.push(this.data[i].id) ;
          }
        }
      },
      deleteSelected() {
        if (confirm("선택한 항목을 삭제하시겠습니까?")) {
          var ids = this.deleteArray.join(",");


          this.$axios
                  .delete("banner", { params: { ids : ids } })
                  .then(response => {
                    if (response.data.success) {
                      this.$alert.show(response.data.message);
                      this.list();
                      this.deleteArray = [];
                      this.allCheckValue = [];
                    } else {
                      this.$alert.show(response.data.message);
                    }
                  })
                  .catch(ex => {
                    if (ex.response.data.message.constructor == Object) {
                      this.$alert.show(ex.response.data.message);
                    }
                  });

        }
      }
    }
  };
</script>
