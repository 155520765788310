<template>
  <div class="sub-container flex-sb">
    <div class="content col" style="width:14%;">
      <section class="section">
        <h2 class="page-title">컨텐츠 관리</h2>
        <div class="contents-menu-box">
          <h3 class="contents-menu-title">
            <a href="#/notice">공지사항 / 이벤트</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/banner">배너</a></h3>
          <h3 class="contents-menu-title"><a href="#/fund">모금자리</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/health-column">건강칼럼</a>
          </h3>
          <h3 class="contents-menu-title"><a href="#/faq">FAQ</a></h3>
          <h3 class="contents-menu-title">
            <a href="#/option">지원과목 / 지원항목</a>
          </h3>
        </div>
      </section>
    </div>
    <div class="content col" style="width:86%;">
      <section class="section">
        <h3 class="section-title" style="margin-top:24px;">
          {{ contentsTitle }}
        </h3>
        <div class="table-search">
          <div class="ts-sch-col">
            <input
              type="text"
              class="ts-sch-col__inp inp-txt-common"
              placeholder="제목, 본문 검색"
              v-model="keyword"
              @keyup.enter="search()"
            />
            <button class="ts-sch-col__btn" @click="search()">
              <span class="is-blind" >검색하기</span>
            </button>
          </div>
<!--          <div class="ts-date-picker">-->
<!--            <input-->
<!--              type="date"-->
<!--              class="ts-date-picker__inp inp-txt-common from"-->
<!--              placeholder="부터"-->
<!--              v-model="start"-->
<!--            />-->
<!--            <input-->
<!--              type="date"-->
<!--              class="ts-date-picker__inp inp-txt-common to"-->
<!--              placeholder="까지"-->
<!--              v-model="end"-->
<!--            />-->
<!--          </div>-->

          <div class="ts-button">
            <button class="btn-search" @click="search()">검색하기</button>
          </div>
        </div>
        <div class="table-util">
          <div class="util-start">
            <button class="btn-del-select" @click="deleteSelected()">
              <span class="txt">선택항목삭제</span>
            </button>
            <span class="us-txt">검색결과:  {{total}}건</span>
          </div>
          <div class="util-end">
            <div class="tue-select select-wrap">
              <select v-model="param.pageSize" @change="list">
                <option value="10">10개씩 보기</option>
                <option value="20">20개씩 보기</option>
                <option value="30">30개씩 보기</option>
                <option value="50">50개씩 보기</option>
              </select>
            </div>
          </div>
        </div>
        <table class="tbl-list-default application-mng">
          <colgroup>
            <col class="col01" />
            <col class="col01" />
            <col class="col15" />
            <col class="col07" />
            <col class="col13" />
            <col class="col16" />
            <col class="col16" />
          </colgroup>
          <thead>
            <tr>
              <th>
                <input type="checkbox" ref="check-all" v-model="allCheckValue" value="1" id="chkAll" @click="clickAllCheckbox()" class="checkbox" />
                <label for="chkAll" class="is-blind">전체선택</label>
              </th>
              <th>번호</th>
              <th>분류</th>
              <th>제목</th>
              <th>내용</th>
              <th>등록자</th>
              <th>등록일</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in data"
              v-bind:key="row.id"

              style="cursor: pointer"
            >
              <td>
                <input type="checkbox" :id="('checkbox_' + row.id)" v-model="deleteArray" :value="row.id" class="checkbox" />
                <label :for="('checkbox_' + row.id)" class="is-blind">선택</label>
              </td>
              <td  @click="open(row.id)">
                {{ row.id }}
              </td>
              <td  @click="open(row.id)">
                {{ row.type}}
              </td>
              <td  @click="open(row.id)">{{ row.title }}</td>
              <td style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;" @click="open(row.id)">{{ row.memo  | stripHTML  }}</td>
              <td  @click="open(row.id)">{{ row.name }}</td>
              <td  @click="open(row.id)">{{ row.createdAt.substring(0, 16).replace("T", " ") }}</td>
            </tr>
          </tbody>
        </table>
        <div class="table-foot clearfix">
          <Pagination
            :location="$route.path"
            ref="pagination"
            :page="nowPage"
            :page-size="param.pageSize"
            :keyword="param.keyword"
          ></Pagination>
          <button class="btn-confirm tf-btn" @click="add()">
            <span class="txt ico apply-w">{{ contentsTitle }} 등록</span>
          </button>
        </div>
      </section>
    </div>
  </div>
</template>
<style></style>
<script>
  import Pagination from "../components/Pagination";
export default {
  name: "Contents",
  components: {Pagination},
  data: () => ({
    contentsType: "notice",
    contentsTitle: "공지사항",
    param: {
      page: 0,
      pageSize: 10,
      start: null,
      end: null,
      keyword: null
    },
    keyword: null,
    start:null,
    end:null,
    nowPage: 1,
    total: 0,
    data: [],
    deleteArray : [],
    allCheckValue:[],
  }),
  mounted() {

    if (this.$route.query.pageSize == undefined) {
      this.param.pageSize = 10 ;
    } else {
      this.param.pageSize = this.$route.query.pageSize;
    }
  },
  beforeMount() {
    if (this.$route.path == "/notice") {
      this.contentsType = "notice";
      this.contentsTitle = "공지사항";
    } else if (this.$route.path == "/banner") {
      this.contentsType = "banner";
      this.contentsTitle = "배너";
    } else if (this.$route.path == "/fund") {
      this.contentsType = "fund";
      this.contentsTitle = "모금자리";
    } else if (this.$route.path == "/health-column") {
      this.contentsType = "health-column";
      this.contentsTitle = "건강칼럼";
    } else if (this.$route.path == "/faq") {
      this.contentsType = "faq";
      this.contentsTitle = "FAQ";
    } else if (this.$route.path == "/support") {
      this.contentsType = "support";
      this.contentsTitle = "지원과목/지원항목";
    }

    if (this.$route.query.keyword != undefined) {
      this.keyword = this.$route.query.keyword;
    }

    if (this.$route.query.page != '' && this.$route.query.page != null) {
      this.nowPage = this.$route.query.page;
    } else {
      this.nowPage = 1;
    }
    this.list();
  },

  methods: {
    add() {
      if (this.contentsType == "notice") {
        this.$router.push({ path: "notice/new" });
      } else if (this.contentsType == "banner") {
        this.$router.push({ path: "banner/new" });
      } else if (this.contentsType == "fund") {
        this.$router.push({ path: "fund/new" });
      } else if (this.contentsType == "health-column") {
        this.$router.push({ path: "health-column/new" });
      } else if (this.contentsType == "faq") {
        this.$router.push({ path: "faq/new" });
      } else if (this.contentsType == "support") {
        this.$router.push({ path: "support/new" });
      }
    },
    open(id) {
      if (this.contentsType == "notice") {
        this.$router.push({ path: "notice/" + id });
      } else if (this.contentsType == "banner") {
        this.$router.push({ path: "banner/" + id });
      } else if (this.contentsType == "fund") {
        this.$router.push({ path: "fund/" + id });
      } else if (this.contentsType == "health-column") {
        this.$router.push({ path: "health-column/" + id });
      } else if (this.contentsType == "faq") {
        this.$router.push({ path: "faq/" + id });
      } else if (this.contentsType == "support") {
        this.$router.push({ path: "support/" + id });
      }
    },
    request() {
      this.param.page = this.nowPage -1;
      this.param.keyword = this.keyword;
      this.param.start = this.start;
      this.param.end = this.end;
      if (this.contentsType == "notice") {
        return  this.$axios.get("notices", { params: this.param });
      } else if (this.contentsType == "banner") {
        return  this.$axios.get("banners", { params: this.param });
      } else if (this.contentsType == "fund") {
        return  this.$axios.get("funds", { params: this.param });
      } else if (this.contentsType == "health-column") {
        return  this.$axios.get("health-columns", { params: this.param });
      } else if (this.contentsType == "faq") {
        return  this.$axios.get("faqs", { params: this.param });
      } else if (this.contentsType == "support") {
        return  this.$axios.get("support", { params: this.param });
      }
    },
    search() {
      console.log("search");
      let paramName = "page";
      const page = 1;
      var queryJson = "{ \"" + paramName + "\":" + page;
      let pageSizeName = "pageSize";
      queryJson = queryJson + " ,\"" + pageSizeName + "\":" + this.param.pageSize ;
      if (this.keyword != undefined && this.keyword != null ) {
        queryJson = queryJson + " ,\"keyword\":\"" + this.keyword +"\"" ;
      }


      queryJson = queryJson + " }";

      const queryParam = JSON.parse(queryJson);
      console.log(queryParam);
      this.$router.push({ path: this.contentsType, query: queryParam });
    },
    async list() {
      const getListPromise = await this.request();
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.data = response.data.data;
        this.total = response.data.total;
        this.$refs["pagination"].setTotal(this.total);
        console.log("#####",this.total);
      }
    },
    clickAllCheckbox() {
      if (this.deleteArray.length == this.param.pageSize) {
        this.deleteArray = [];
      } else {
        this.deleteArray = [];
        for(var i=0;i<this.data.length;i++) {

          this.deleteArray.push(this.data[i].id) ;
        }
      }
    },
    deleteSelected() {
      if (confirm("선택한 항목을 삭제하시겠습니까?")) {
        var ids = this.deleteArray.join(",");
        var url = "";
        if (this.contentsType == "notice") {
          url = "notice";
        } else if (this.contentsType == "banner") {
          url = "banner";
        } else if (this.contentsType == "fund") {
          url = "fund";
        } else if (this.contentsType == "health-column") {
          url = "health-column";
        } else if (this.contentsType == "faq") {
          url = "faq";
        } else if (this.contentsType == "support") {
          url = "support";
        }
        this.$axios
                .delete(url, { params: { ids : ids } })
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    this.list();
                    this.deleteArray = [];
                    this.allCheckValue = [];
                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });

      }
    }
  }
};
</script>
