<template>
  <span>{{ commaValue }}</span>
</template>
<script>
export default {
  name: "CommaLabel",
  props: ["value"],
  data() {
    return {
      modelValue: "",
      commaValue: "0"
    };
  },
  mounted() {
    setTimeout(() => {
      console.log("this.modelValue ; " + this.value);
      if (this.value !== undefined && this.value !== null) {
        this.modelValue = this.value;
        this.commaValue = this.modelValue.toLocaleString("en-US");
      }
    }, 500);
  },
  beforeMount() {
    if (this.value !== undefined && this.value !== null) {
      this.modelValue = this.value;
      this.commaValue = this.modelValue.toLocaleString("en-US");
    }
  },


};
</script>
<style scoped></style>
