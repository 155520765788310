<template>
  <div class="sub-container">
    <div class="content">
      <h2 class="page-title">1:1 문의</h2>

      <div class="table-search">
        <div class="ts-sch-col">
          <input
            type="text"
            class="ts-sch-col__inp inp-txt-common"
            placeholder="이름, 내용으로 검색"
            v-model="param.keyword"
            @keyup.enter="search()"
          />
          <button class="ts-sch-col__btn" @click="search">
            <span class="is-blind">검색하기</span>
          </button>
        </div>

        <div class="ts-select select-wrap">
          <select v-model="param.qnaType" @change="search">
            <option value="">문의 타입</option>
            <option value="member">회원</option>
            <option value="hospital">병원</option>
          </select>
        </div>
        <div class="ts-select select-wrap" v-if="param.qnaType == 'hospital'">
          <select v-model="param.category" @change="search">
            <option value="">분류 전체</option>
            <option value="시스템">시스템</option>
            <option value="신청자">신청자</option>
            <option value="정산">정산</option>
            <option value="지원사업">지원사업</option>
            <option value="기타">기타</option>
          </select>
        </div>
        <div class="ts-select select-wrap">
          <select v-model="param.answered" @change="search">
            <option value="">답변유무</option>
            <option value="true">답변</option>
            <option value="false">미답변</option>
          </select>
        </div>
        <div class="ts-button">
          <button class="btn-search" @click="search()">검색하기</button>
        </div>
      </div>

      <div class="table-util">
        <div class="util-start">
          <button class="btn-del-select" @click="deleteSelected()">
            <span class="txt">선택항목삭제</span>
          </button>
          <button class="btn-down-excel" @click="downloadExcel">
            <span class="txt">엑셀다운로드</span>
          </button>

          <span class="us-txt"
            >검색결과: 전체 {{ total }}건 중 {{ qnaData.length }}건</span
          >
        </div>
        <div class="util-end">
          <div class="tue-select select-wrap">
            <select v-model="param.pageSize" @change="list">
              <option value="10">10개씩 보기</option>
              <option value="20">20개씩 보기</option>
              <option value="30">30개씩 보기</option>
              <option value="50">50개씩 보기</option>
            </select>
          </div>
        </div>
      </div>

      <table class="tbl-list-default application-mng">
        <colgroup>
          <col class="col01" />
          <col class="col02" />
          <col class="col03" />
          <col class="col07" />
          <col class="col14" />
          <col class="col08" />
        </colgroup>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                ref="check-all"
                v-model="allCheckValue"
                value="1"
                id="chkAll"
                @click="clickAllCheckbox()"
                class="checkbox"
              />
              <label for="chkAll" class="is-blind">전체선택</label>
            </th>
            <th>회원명</th>
            <th>타입</th>
            <th>분류</th>
            <th>질문</th>
            <th>등록일</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(qna ,index ) in qnaData">
            <tr v-bind:key="index  ">
              <td>
                <input
                  type="checkbox"
                  :id="'checkbox_' + qna.qnaId"
                  v-model="deleteArray"
                  :value="qna.qnaId"
                  class="checkbox"
                />
                <label :for="'checkbox_' + qna.qnaId" class="is-blind"
                  >선택</label
                >
              </td>

              <td>
                {{ qna.qnaType == "hospital" ? qna.hospitalName : qna.name }}
              </td>
              <td>{{ qna.qnaType == "hospital" ? "병원" : "회원" }}</td>
              <td>{{ qna.category }}</td>
              <td
                @click="openQna(qna,index)"
                style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap; cursor: pointer"
              >
                {{ qna.question }}
              </td>
              <td>
                {{ qna.createdAt.substring(0, 16).replace("T", " ") }}
              </td>
            </tr>
            <tr v-bind:key="qna.qnaId + '_question_' + qna.show "  v-if="qna.show" style="background-color: #FAFAFA">
              <td colspan="3"></td>
              <td>
                문의
              </td>
              <td colspan="2" >
                <p class="txt" style="word-wrap:break-word" v-html="handleNewLine(qna.question)"></p>

              </td>
            </tr>
            <tr v-bind:key="qna.qnaId + '_answer_'  + qna.show " v-if="qna.show" style="background-color: #FAFAFA">
              <td colspan="3"></td>
              <td>
                답변
              </td>
              <td colspan="2" >
                <div class="ask-view__con" v-if="qna.answeredAt != null && qna.answerMode != 'edit'">

                  <p  class="txt" style="word-wrap:break-word" v-html="handleNewLine(qna.answer)"></p>
                  <div class="con-tail" >
                    <span class="date">{{ qna.answeredAt != null ? qna.answeredAt.substring(0, 16).replace("T", " ") : ""}}</span>
                    <span class="writer">작성자 : {{qna.adminName}}</span>
                    <button class="btn-mod" @click="changeAnswerMode(qna,index)">수정하기</button>
                    <button class="btn-del" @click="deleteAnswer(qna)">삭제하기</button>
                  </div>

                </div>
                <div class="ask-view__con" v-if="qna.answeredAt == null || qna.answerMode == 'edit'">
                  <div class="ask-view__reply">
                      <textarea
                              class="reply-text-area"
                              placeholder="답글을 입력해주세요."
                              v-model="qna.answer"
                      ></textarea>
                    <button class="btn-outline reply" @click="updateAnswer(qna)">
                      <span class="txt">쓰기</span>
                    </button>
                  </div>
                </div>
              </td>

            </tr>
          </template>
        </tbody>
      </table>

      <div class="table-foot clearfix">
        <Pagination
          location="qna"
          ref="pagination"
          :page="nowPage"
          :page-size="param.pageSize"
          :keyword="param.keyword"
          :answered="param.answered"
          :qnaType="param.qnaType"
          :category="param.category"
        ></Pagination>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import Pagination from "../components/Pagination";
import Xlsx from "xlsx";
export default {
  name: "Qna",
  components: { Pagination },
  data: () => ({
    nowPage: 1,
    total: 0,
    param: {
      page: 0,
      pageSize: 10,
      start: null,
      end: null,
      keyword: null,
      qnaType: "",
      category: "",
      answered: ""
    },
    qnaData: [],
    deleteArray: [],
    allCheckValue: [],
  }),
  mounted() {
    console.log(this.$route.path);
  },
  beforeMount() {
    if (this.$route.query.page != "" && this.$route.query.page != null) {
      this.nowPage = this.$route.query.page;
    } else {
      this.nowPage = 1;
    }

    if (this.$route.query.pageSize == undefined) {
      this.param.pageSize = 10;
    } else {
      this.param.pageSize = this.$route.query.pageSize;
    }

    if (this.$route.query.keyword != undefined) {
      this.param.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.qnaType != undefined) {
      this.param.qnaType = this.$route.query.qnaType;
    }
    if (this.$route.query.category != undefined) {
      this.param.category = this.$route.query.category;
    }
    if (this.$route.query.answered != undefined) {
      this.param.answered = this.$route.query.answered;
    }
    this.list();
  },
  methods: {
    handleNewLine(str) {
      return String(str).replace(/(?:\r\n|\r|\n)/g,"</br>");
    },
    openQna(qna,index) {



      if (qna.show != true) {
        qna.show = true;
      } else {
        qna.show = false;
      }
      const copied = { ...qna };

      this.$set(this.qnaData,index,copied) ;


      console.log(qna.show);
    },
    requestList() {
      this.param.page = this.nowPage - 1;
      this.param.start = this.start;
      this.param.end = this.end;

      return this.$axios.get("qnas", { params: this.param });
    },
    search() {
      console.log("search");
      let paramName = "page";
      const page = 1;
      var queryJson = "{ \"" + paramName + "\":" + page;
      let pageSizeName = "pageSize";
      queryJson = queryJson + " ,\"" + pageSizeName + "\":" + this.param.pageSize ;
      if (this.param.keyword != undefined && this.param.keyword != null ) {
        queryJson = queryJson + " ,\"keyword\":\"" + this.param.keyword +"\"" ;
      }
      if (this.param.qnaType != undefined && this.param.qnaType != null ) {
        queryJson = queryJson + " ,\"qnaType\":\"" + this.param.qnaType +"\"" ;
      }

      if (this.param.answered != undefined && this.param.answered != null ) {
        queryJson = queryJson + " ,\"answered\":\"" + this.param.answered +"\"" ;
      }

      if (this.param.category != undefined && this.param.category != null ) {
        queryJson = queryJson + " ,\"category\":\"" + this.param.category +"\"" ;
      }

      queryJson = queryJson + " }";

      const queryParam = JSON.parse(queryJson);
      console.log(queryParam);
      this.$router.push({ path: "qna", query: queryParam });
    },
    async list() {
      const getListPromise = await this.requestList();
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.qnaData = response.data.data;
        // for(let i=0; i<this.qnaData.length; i++) {
        //   this.showMap[this.qnaData[i].qnaId] = false;
        // }
        this.total = response.data.total;
        this.$refs["pagination"].setTotal(this.total);
        console.log("#####", this.total);
      }
    },
    clickAllCheckbox() {
      if (this.deleteArray.length == this.param.pageSize) {
        this.deleteArray = [];
      } else {
        this.deleteArray = [];
        for (var i = 0; i < this.data.length; i++) {
          this.deleteArray.push(this.data[i].qnaId);
        }
      }
    },
    deleteSelected() {
      if (confirm("선택한 항목을 삭제하시겠습니까?")) {
        var ids = this.deleteArray.join(",");

        this.$axios
          .delete("member", { params: { ids: ids } })
          .then(response => {
            if (response.data.success) {
              this.$alert.show(response.data.message);
              this.list();
              this.deleteArray = [];
              this.allCheckValue = [];
            } else {
              this.$alert.show(response.data.message);
            }
          })
          .catch(ex => {
            if (ex.response.data.message.constructor == Object) {
              this.$alert.show(ex.response.data.message);
            }
          });
      }
    },
    downloadExcel() {
      const workBook = Xlsx.utils.book_new();
      const workSheet = Xlsx.utils.json_to_sheet(this.qnaData);
      Xlsx.utils.book_append_sheet(workBook, workSheet, "병원");
      Xlsx.writeFile(workBook, "1:1문의.xlsx");
    },
    changeAnswerMode(qna,index) {
      qna.answerMode = "edit";


      const copied = { ...qna };

      this.$set(this.qnaData,index,copied) ;
    },
    deleteAnswer(qna) {
      if (confirm("답변 항목을 삭제하시겠습니까?")) {

        this.$axios
                .delete("qna/answer", { params: { qnaId: qna.qnaId } })
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    this.list();
                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });
      }
    },
    async updateAnswer(qna) {
      var param = {
        qnaId: qna.qnaId,
        answer: qna.answer,
      }

      this.$axios
              .put("qna/answer", param)
              .then(response => {
                if (response.data.success) {
                  this.$alert.show(response.data.message);
                  this.list();
                } else {
                  this.$alert.show(response.data.message);
                }
              })
              .catch(ex => {
                if (ex.response.data.message.constructor == Object) {
                  this.$alert.show(ex.response.data.message);
                }
              });
    },
  }
};
</script>
